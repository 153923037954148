import React from "react";
export default function Button(props) {
  const notAllowed = props.disabled ? "notAllowed" : "";
  return (
    <div
      className={`RlPs ${props.clName} ${notAllowed}`}
      onClick={props.onClick}
    >
      <button
        className={`rmOut FntfMin StBrdRd BdgBlcl DlBrd StSizLn StWht stbold CrsPoi BtNset FlWd ${
          props.danger ? "TxDng" : ""
        } ${props.gray ? "Gray" : ""} button-base`}
        style={props.style}
        disabled={props.disabled}
        type={props.type}
        title={props.title}
      >
        {props.BtnText}
        {props.suffix && <span className="button-suffix-icon">{ props.suffix }</span>}
      </button>
    </div>
  );
}
