import React, { useState } from "react";
import { Translate } from "../../utils/lang/translate";
import { copySvg } from "../StorePages/_Common/IconSvg";
import Button from "./Button";

export default function GroupeForm(props) {
  const readOnly =
    props.readOnly && props.readOnly.toString() === "true" ? true : false;
  const disabled =
    props.disabled && props.disabled.toString() === "true" ? true : false;

  return (
    <div className="grp-fm StdirLn StFnt" style={props.style}>
      <div className="SsGrpRm RlPs InFlx responsFlx spcBtwn flxDrc">
        <label htmlFor={props.id}>
          {props.text}
          {props.optional && <span> ({Translate("shop", "optional")})</span>}
        </label>
        <div className="StOpcVal">{props.more}</div>
      </div>

      {props.name === "phone" ? (
        <div className="SsGrpRm StMrg RlPs">
          <div className="FntfMin lytwdp2p StdirLn FrInp StSizLn StHgInp StBrdRd Fntcl ">
            {props.select}
            {props.textarea ? (
              <textarea
                className={props.stclass}
                style={{ resize: "none", height: "157px", paddingTop: "15px" }}
                onChange={props.workfun}
                onKeyDown={props.onKeyDown}
                name={props.name}
                placeholder={props.placeholder}
                id={props.id}
                value={props.value}
              ></textarea>
            ) : (
              <input
                className={props.stclass}
                type={props.type}
                name={props.name}
                id={props.id}
                defaultValue={props.defaultValue}
                value={props.value}
                max={props.max}
                maxLength={props.maxLength}
                min={props.min}
                placeholder={props.placeholder}
                pattern={props.pattern}
                onChange={props.workfun}
                onKeyDown={props.onKeyDown}
                autoComplete="off"
                aria-autocomplete={"none"}
                readOnly={readOnly}
                disabled={disabled}
                style={props.inputStyle}
              />
            )}
          </div>
          {props.copyButton && (
            <div
              style={{
                position: "absolute",
                top: "10px",
                right: "13px",
                cursor: "pointer",
              }}
              onClick={() => {
                navigator.clipboard.writeText(props.value);
                props.copyButton();
              }}
            >
              {copySvg}
            </div>
          )}
          <span className="StRedClr">{props.error}</span>
          {props.icon && (
            <div
              className="StAbs FlHg InFlx AlgnItm StOpcVal"
              style={{ right: "5px", bottom: "0" }}
            >
              {props.icon}{" "}
            </div>
          )}
        </div>
      ) : (
        <div className="SsGrpRm StMrg RlPs">
          {props.children ? (
            props.children
          ) : props.textarea ? (
            <textarea
              className={
                "FntfMin lytwdp2p StdirLn FrInp StSizLn StHgInp StBrdRd Fntcl " +
                props.stclass
              }
              style={{ resize: "none", height: "157px", paddingTop: "15px" }}
              onChange={props.workfun}
              onKeyDown={props.onKeyDown}
              name={props.name}
              placeholder={props.placeholder}
              id={props.id}
              value={props.value}
            ></textarea>
          ) : (
            <input
              className={
                "FntfMin lytwdp2p StdirLn FrInp StSizLn StHgInp StBrdRd Fntcl " +
                props.stclass
              }
              type={props.type}
              name={props.name}
              id={props.id}
              defaultValue={props.defaultValue}
              value={props.value}
              max={props.max}
              maxLength={props.maxLength}
              min={props.min}
              placeholder={props.placeholder}
              pattern={props.pattern}
              onChange={props.workfun}
              onKeyDown={props.onKeyDown}
              autoComplete="off"
              aria-autocomplete={"none"}
              readOnly={readOnly}
              disabled={disabled}
              style={props.inputStyle}
            />
          )}
          {props.copyButton && (
            <div
              style={{
                position: "absolute",
                top: "10px",
                right: "13px",
                cursor: "pointer",
              }}
              onClick={() => {
                navigator.clipboard.writeText(props.value);
                props.copyButton();
              }}
            >
              {copySvg}
            </div>
          )}
          <span className="StRedClr">{props.error}</span>
          {props.icon && (
            <div
              className="StAbs FlHg InFlx AlgnItm StOpcVal"
              onClick={props.onClick}
              style={{ right: "5px", bottom: "0" }}
            >
              {props.icon}{" "}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
