import React, { Fragment } from "react";
import { Translate } from "../../utils/lang/translate";

export default function SelectFormSearch(props) {
  return (
    <div className="grp-fm StdirLn StFnt">
      <div className="SsGrpRm RlPs">
        <label htmlFor={props.id}>
          {props.text}
          {props.optional && <span> ({Translate("shop", "optional")})</span>}
        </label>
        <div className="StAbs StLanNl StTpNl StOpcVal">{props.more}</div>
      </div>
      <div className="SsGrpRm StMrg RlPs">
        <Fragment>
          <input
            list={props.id + "search"}
            autoComplete="off"
            value={props.value}
            id={props.id}
            name={props.name}
            className={
              "lytwdp2p Fntcl StdirLn FrInp StSizLn StHgInp StBrdRd StOpcVal FntfMin " +
              (props.stclass ? props.stclass : "")
            }
            data_key=""
            placeholder={
              props.loading ? Translate("alert", "load") : props.placeholder
            }
            onChange={props.workfun}
          />
          {!props.loading && (
            <datalist id={props.id + "search"}>
              {Array.isArray(props.option)
                ? props.option.map((opt, key) => {
                    return (
                      <option
                        key={key}
                        value={props.index ? opt[props.index] : opt[1]}
                      />
                    );
                  })
                : ""}
            </datalist>
          )}
        </Fragment>
        <span className="StRedClr">{props.error}</span>
      </div>
    </div>
  );
}
