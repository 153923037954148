import React, { useState } from "react";
import DayPicker, { DateUtils } from "react-day-picker";
import Cookies from "js-cookie";
import "./PostponeDatePicker.scss";
import { Translate } from "../../../utils/lang/translate";

const WEEKDAYS_SHORT = {
  ar: ["جمـ", "خميـ", "أربـ", "ثلثـ", "إثنـ", "أحـ", "سبـ"],
  fr: ["Sam", "Dim", "Lun", "Mar", "Mer", "Jeu", "Ven"],
};
const MONTHS = {
  ar: [
    "جانفي",
    "فيفري",
    "مارس",
    "افريل",
    "ماي",
    "جوان",
    "جويلية",
    "اوت",
    "سبتمبر",
    "اكتوبر",
    "توفمبر",
    "ديسمبر",
  ],
  fr: [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Aout",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ],
};

const WEEKDAYS_LONG = {
  ar: ["السبت", "الاحد", "الاثنين", "الثلثاء", "الاربعاء", "الخميس", "الجمعة"],
  fr: ["Samedi", "Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi"],
};

const now = new Date();
const dayInSeconds = 60 * 60 * 24 * 1000;
const tomorrow = new Date(now.getTime() + dayInSeconds);

const PostponeDatePicker = ({
  closeDatePicker,
  sendPostponeDate,
}: {
  closeDatePicker: () => void;
  sendPostponeDate: (postponed_to: string) => void;
}) => {
  const [selectedDate, setSelectedDate] = useState<Date>();
  const lang = Cookies.get("lang");

  const handleConfirm = () => {
    if (selectedDate) {
      const month = selectedDate.getUTCMonth() + 1;
      const day = selectedDate.getUTCDate();
      const year = selectedDate.getUTCFullYear();
      sendPostponeDate(`${year}-${month}-${day}`);
      closeDatePicker();
    }
  };

  const isDisabledClass = selectedDate ? "" : "disabled";

  if (!lang) return <div />;
  return (
    <div className="PostponeDatePicker">
      <div className="shadow" onClick={closeDatePicker} />
      <div className="container">
        <p>{Translate("shop", "postponeDatePickerText")}</p>
        <DayPicker
          locale={lang}
          // @ts-ignore
          months={MONTHS[lang]}
          // @ts-ignore
          weekdaysLong={WEEKDAYS_LONG[lang]}
          // @ts-ignore
          weekdaysShort={WEEKDAYS_SHORT[lang]}
          selectedDays={selectedDate}
          onDayClick={setSelectedDate}
          fromMonth={now}
          disabledDays={{ before: tomorrow }}
          labels={{
            nextMonth: "Next Month",
            previousMonth: "Previous Month",
          }}
        />
        <div className="buttons">
          <button className="cancel" onClick={closeDatePicker}>
            {Translate("profile", "cancel")}
          </button>
          <button
            className={`confirm ${isDisabledClass}`}
            onClick={handleConfirm}
          >
            {Translate("shop", "confirm")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PostponeDatePicker;
