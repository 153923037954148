import React, { useEffect, useMemo, useState } from "react";
import { ordersActions } from "../../../actions";
import { connect } from "react-redux";
import {
  UnfOrdDetailsProduct,
  UnfulfilledOrderDetailsInterface,
} from "../../../types";
import Loader from "../../_Common/Loader";
import ContainerPage from "../_Common/ContainerPage";
import { Link } from "react-router-dom";
import {
  FormatCurrency,
  FormatDate,
  Translate,
} from "../../../utils/lang/translate";
import Table from "../_Common/Table";
import ListDetailIcon from "../_Common/ListDetailIcon";
import {
  LocationSvg,
  PackageSvg,
  PersonSvg,
  PriceSvg,
  CallSvg,
  CallBackActiveSvg,
  PostponeActiveSvg,
} from "../_Common/IconSvg";
import "./UnfulfilledOrderDetails.scss";

type Props = {
  history: any;
  match: any;
  GetAllUnfOrders: () => void;
  GetUnfOrderTimeline: (id: string) => void;
  GetinunfOrd?: "1" | "2" | "3";
  dataInfOrd?: any;
  dtStoreinfo?: any;
  GettingUnfOrdTimeline?: "1" | "2" | "3";
  dataUnfOrTimeline?: {
    created_at: Date;
    status: number;
    temp_order: string;
    updated_by: number;
  }[];
};

const getStatusIcon = (status: number) => {
  switch (status) {
    case 20:
      return CallBackActiveSvg;
    default:
      return PostponeActiveSvg;
  }
};

const UnfulfilledOrderDetails = ({
  history,
  match,
  GetAllUnfOrders,
  GetUnfOrderTimeline,
  GetinunfOrd,
  dataInfOrd,
  dtStoreinfo,
  GettingUnfOrdTimeline,
  dataUnfOrTimeline,
}: Props) => {
  const [orderDetails, setOrderDetails] =
    useState<null | UnfulfilledOrderDetailsInterface>(null);

  useEffect(() => {
    GetAllUnfOrders();
  }, [match.params.idprod]);

  useEffect(() => {
    if (GetinunfOrd === "3") history.push("/unfulfilled/order");
    if (GetinunfOrd === "2" && typeof dataInfOrd !== "string") {
      const tempOrders = dataInfOrd.ressuc
        .results as UnfulfilledOrderDetailsInterface[];
      const theTempOrder = tempOrders.find(
        (order) => order.display_id === parseInt(match.params.idprod)
      );
      if (!theTempOrder) {
        history.push("/unfulfilled/order");
      } else {
        setOrderDetails(theTempOrder);
        GetUnfOrderTimeline(theTempOrder.id);
      }
    }
  }, [GetinunfOrd]);

  const ConcatProduStr = (products: UnfOrdDetailsProduct[]) =>
    Array.isArray(products) &&
    products.map((elem) => {
      return [
        <div className="wharp_spce">
          {` (${elem.quantity}) ${elem.logistical_description}`}
        </div>,
      ];
    });

  let shippedUnique = useMemo(() => {
    let obj: any = {};
    if (dataUnfOrTimeline && Array.isArray(dataUnfOrTimeline)) {
      dataUnfOrTimeline.forEach((oh) => {
        if (!obj[oh.status]) obj[oh.status] = [oh];
        else obj[oh.status].push(oh);
      });
    }
    return obj;
  }, [dataUnfOrTimeline]);

  const statusTimeline = useMemo(
    () =>
      Object.keys(shippedUnique)?.map((k, index) => {
        let key = parseInt(k);
        let x = key === 31 ? shippedUnique[key] : shippedUnique[key][0];
        let details: any[];
        if (Array.isArray(x) && x.length > 1) {
          details = x.map((y, index) => {
            return [
              FormatDate(y.created_at),
              <span
                className={`StAbs status_${y.status} LeftSet StTpval2 WdSmP HidLstELm_${index}`}
                style={{ height: "100%" }}
              ></span>,
            ];
          });
        } else {
          if (x.length) x = x[0];
          details = [
            FormatDate(x.created_at),
            <span
              className={`StAbs status_${x.status} LeftSet StTpval2 WdSmP HidLstELm_${index}`}
              style={{ height: "100%" }}
            ></span>,
          ];
        }
        return [
          <ListDetailIcon
            icon={getStatusIcon(key)}
            title={Translate("statusorder", key + "")}
            details={details}
          />,
        ];
      }),
    [shippedUnique]
  );

  return GetinunfOrd !== "2" ||
    GettingUnfOrdTimeline !== "2" ||
    !orderDetails ? (
    <Loader />
  ) : (
    <ContainerPage
      page_title={
        <div>
          <Link
            className="StOpcVal"
            to={`/unfulfilled/order${dataInfOrd.filter ?? ""}`}
          >
            {Translate("titles", "unfulfilledorder")}
          </Link>
          <div className="Inlflx StWdDst">{Translate("orders", "flech")}</div>
          <div className="Inlflx">{Translate("orders", "orderdetail")}</div>
          <div className="Inlflx StWdDst">#</div>
          <div className="Inlflx">{orderDetails.display_id}</div>
        </div>
      }
    >
      <div className="FlWd InFlx responsFlx flxDrc UnfulfilledOrderDetails">
        <div className="Hlwd">
          <Table
            ntdir={true}
            thead_elem={[Translate("orders", "detailorder")]}
            tbody_elem={[
              [
                <ListDetailIcon
                  icon={PackageSvg}
                  title={Translate("product", "productname")}
                  details={ConcatProduStr(orderDetails.products)}
                />,
              ],
              [
                <div className="FlWd InFlx responsFlx flxDrc">
                  <div>
                    <div className="Hlwd">
                      <ListDetailIcon
                        icon={PriceSvg}
                        title={Translate("orders", "totalprice")}
                        details={
                          dtStoreinfo?.country?.id === 1
                            ? [
                                FormatCurrency.format(
                                  orderDetails.product_price
                                ),
                              ]
                            : [`${orderDetails.product_price} TND`]
                        }
                      />
                    </div>
                  </div>
                </div>,
              ],
              [
                <div className="FlWd InFlx responsFlx flxDrc">
                  <div className="Hlwd Mrg2">
                    <ListDetailIcon
                      icon={LocationSvg}
                      title={Translate("orders", "district")}
                      details={[
                        `${orderDetails.commune} (${orderDetails.wilaya})`,
                      ]}
                      commune
                    />
                  </div>
                </div>,
              ],
              [
                <ListDetailIcon
                  icon={PersonSvg}
                  title={Translate("orders", "customername")}
                  details={[orderDetails.customer_name]}
                />,
              ],
              [
                <ListDetailIcon
                  icon={CallSvg}
                  title={Translate("orders", "customerphonenumber")}
                  details={[orderDetails.customer_phone]}
                />,
              ],
            ]}
          />
        </div>
        <div className="Hlwd Mrg2">
          <div className="delivery-status">
            <Table
              ntdir={true}
              thead_elem={[Translate("pickups", "timeline")]}
              tbody_elem={statusTimeline}
            />
          </div>
        </div>
      </div>
    </ContainerPage>
  );
};

function mapState(state: any) {
  const { GetinunfOrd, dataInfOrd, dataUnfOrTimeline, GettingUnfOrdTimeline } =
    state.unfOrders;
  const { dtStoreinfo } = state.user;
  return {
    GetinunfOrd,
    dataInfOrd,
    dtStoreinfo,
    dataUnfOrTimeline,
    GettingUnfOrdTimeline,
  };
}

const actionCreators = {
  GetAllUnfOrders: ordersActions.GetAllUnfOrders,
  GetUnfOrderTimeline: ordersActions.GetUnfOrderTimeline,
};

export default connect(mapState, actionCreators)(UnfulfilledOrderDetails);
