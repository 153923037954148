import { Bundle, DataShop, LandingPageSettings, ProductShop } from "../types";
type Key = keyof Object;

export const convertTimeIntoString: (value: number) => string = (value) => {
  const hours = Math.floor(value / 3600);
  const minutes = Math.floor((value - hours * 3600) / 60);
  const seconds = value - hours * 3600 - minutes * 60;

  const hoursString = hours === 0 ? "" : `${hours}h `;
  const minutesString = minutes === 0 ? "" : `${minutes}m `;
  const secondsString = `${seconds}s`;

  return hoursString + minutesString + secondsString;
};

export const convertMoneyIntoString: (
  value: number,
  currency: string
) => string = (value, currency) => {
  if (value === 0) return value + currency;
  return (
    value
      .toString()
      .split("")
      .reverse()
      .map((char, i) => (!!(i % 3) || i === 0 ? char : char + ","))
      .reverse()
      .join("") + currency
  );
};

export const filterObjectByChangingValues: (
  object1: Object,
  object2: Object
) => Object = (object1, object2) => {
  let result = {};
  for (const key in object1) {
    if (
      typeof object1[key as Key] !== "undefined" &&
      typeof object2[key as Key] !== "undefined" &&
      JSON.stringify(object1[key as Key]) !==
        JSON.stringify(object2[key as Key])
    ) {
      result = { ...result, [key]: object2[key as Key] };
    }
  }

  return result;
};

export const getProductFromResponse: (data: any) => ProductShop = (data) => {
  return {
    title: data.title,
    slug: data.slug,
    options: data.options?.map((option: any) => {
      return {
        name: option.name,
        quantity: option.quantity,
        reduced_price: option.reduced_price,
        price: option.price,
        id: option.id,
      };
    }),
    images: data.images?.map((image: any) => {
      return {
        url: image.url,
        id: image.id,
        order: image.order,
      };
    }),
    categories: data.categories,
    description: data.description,
    how_to_use: data.how_to_use,
    specifications: data.specifications,
    product_id: data.product_id,
    display_id: data.display_id,
    published: data.published,
    id: data.id,
    landing_page: data.landing_page,
    lang: data.lang,
    rate: data.rate,
    pricing_option: data.pricing_option,
    template_details: [
      { custom_sections: data.template_details[0]?.custom_sections ?? [] },
    ],
    is_variant: data.is_variant,
    variants: data.variants
  };
};

export const getBundleFromResponse: (data: any) => Bundle = (data) => {
  return {
    product_bundle: data.product_bundle?.map((prod: any) => {
      return {
        title: prod.title,
        id: prod.id,
        display_id: prod.display_id,
        product: prod.product,
        quantity: prod.quantity,
      };
    }),
    price: data.price,
    reduced_price: data.reduced_price,
    id: data.id,
    display_id: data.display_id,
    pricing_option: data.pricing_option,
  };
};

export const getSettingsFromResponse: (data: any) => DataShop = (data) => {
  return {
    settings_stage: data.settings_stage,
    logo: data.logo,
    name: data.name,
    website_title: data.website_title,
    store_categories: data.store_categories,
    theme_template: data.theme_template,
    subdomain_slug: data.subdomain_slug,
    pixel_key: data.pixel_key,
    analytics_data: data.analytics_data,
    published: data.published,
    domain: data.domain,
    products: data.products?.map((product: any) => {
      return getProductFromResponse(product);
    }),
    shop_bundles: data.shop_bundles?.map((bundle: any) => {
      return getBundleFromResponse(bundle);
    }),
  };
};

export const getLandingPageSettingsFromResponse: (
  data?: any
) => LandingPageSettings = (data) => {
  return {
    id: data?.id,
    header: data?.header,
    congratulation_message: data?.congratulation_message,
    social_media_url: {
      tiktok: data?.social_media_url?.tiktok,
      facebook: data?.social_media_url?.facebook,
      instagram: data?.social_media_url?.instagram,
    },
    custom_colors: {
      button_bg: data?.custom_colors?.button_bg,
      box_bg: data?.custom_colors?.box_bg,
      font_color: data?.custom_colors?.font_color,
      primary_color: data?.custom_colors?.primary_color,
      btn_font_color: data?.custom_colors?.btn_font_color,
    },
  };
};

export const createFormDataFromObject = (element: Object) => {
  const formData = new FormData();
  for (const key in element) {
    const value: any = element[key as Key];

    if (Array.isArray(value)) {
      value.map((elem: any) => {
        if (
          typeof elem === "object" &&
          !(elem instanceof File) &&
          !Array.isArray(elem) &&
          elem !== null
        ) {
          return formData.append(key, JSON.stringify(elem));
        }
        return formData.append(key, elem);
      });
    } else {
      formData.append(key, value);
    }
  }
  return formData;
};

export const createObjectFromKeys = (element: Object, keys: string[]) => {
  let result = {};
  keys.forEach((key: string) => {
    result = { ...result, [key]: element[key as Key] };
  });
  return result;
};

export const createFormDataFromArray = (array: any[], key: string) => {
  const formData = new FormData();
  array.forEach((elem) => {
    formData.append(key, elem);
  });
  return formData;
};

export const whichWilayaHasPermissionToStopDesk = (code: any) => {
  return (
    code === 16 ||
    code === 1 ||
    code === 2 ||
    code === 3 ||
    code === 4 ||
    code === 5 ||
    code === 6 ||
    code === 7 ||
    code === 12 ||
    code === 13 ||
    code === 15 ||
    code === 17 ||
    code === 19 ||
    code === 23 ||
    code === 25 ||
    code === 30 ||
    code === 31 ||
    code === 39 ||
    code === 45 ||
    code === 47
  );
};

export const checkUserHasShopPermissionById = (id: string) => {
  return true;
  const users = [
    "82bc5c9b-c65d-46ea-b426-c1237d83238e",
    "3a2855aa-9319-481e-ac50-164083b8a297",
    "82bc5c9b-c65d-46ea-b426-c1237d83238e",
    "544756fb-69da-42aa-a627-2aa6a85258e9",
    "a4d8cb3f-a89d-4391-a21d-4a3ec3a96c83",
    "e8efe3ae-07d6-41d4-87fa-267f447c0265",
    "b9d702b3-07ab-471a-a040-b7dafa4dec5d",
    "8353abb8-be14-41c2-bba4-43b51df465dc",
    "a370f230-0062-445e-9786-b794634f782d",
    "59d6bfc8-da37-4e04-9f94-d51ac5a816c5",
    "e49c3b56-50dd-4819-8316-9aa84cdd8af3",
    "f4b66e9e-2c72-43d5-8400-2ecdee4f2faf",
    "99cac869-7bdf-4a33-b512-9239e373e254",
    "c7b9a77a-cced-43f8-8d56-cda8afbddf82",
    "ea585f76-6700-4682-bc8a-b0da80a6034b",
    "120149d0-3f4e-4a39-95c8-528dafe0be9f",
    "8c07be94-8a6e-4032-bfa3-183ddc770a2d",
    "08ca38ad-0154-46d7-ad19-5358f2c675c6",
    "ad3b39ba-664d-4894-b08a-629ca0ee46f6",
    "42b82ac8-76e5-4f3d-aa16-2a5cb6c808bd",
    "e33249de-c8f3-4ccb-9286-6734d2eb8f83",
    "10a1ee75-29e3-4947-a52e-60f066c969c2",
    "47e1b114-975b-410c-b705-5129bcd0683c",
    "0aadaee0-4556-4258-8748-d9404223e331",
    "3aa75460-eb41-44fd-abec-ce8e84c09f2c",
    "118aa70b-317c-4b19-8ea3-5f5c273c19bf",
    "b5fba0d3-5c0d-4a01-804a-6eb2f478925b",
    "383688d3-bb53-4c8c-b651-bbbcbe37a1ff",
    "722ef24b-1002-4d88-8685-61e2b4873855",
    "73f30760-92c9-441f-97ea-4346e6b873ed",
    "d57e2088-4c8f-476b-a28b-09efbc57884c",
    "a9a3901e-01d5-47fa-9be5-4abb5cfb973c",
    "b534e160-330e-4748-95d0-e47cff756c9e",
    "b1c99f0f-dc0f-4dbd-93f8-4d528f81c3ae",
    "415e5499-22e6-4c91-96d2-a507cda2b88f",
    "6880e9b2-137f-4b0d-91db-16a999a550a7",
    "97b4b2be-6bf6-4b3a-a81b-22f44bbb6542",
    "bb90d0cc-1576-4f8d-a79c-6defa04c88b3",
    "e3002470-e07a-4ff3-966b-5705740b28c5",
    "f36b100f-2ec0-4472-9ae5-94f582895af8",
    "459d58b4-edea-4d93-a5d1-055233aabf17",
    "acd29e2f-dfbc-48c9-9f66-12e95c920ac1",
    "59768a7a-4e01-4900-9bf0-5faffc5f56bc",
    "1b0e2324-245c-4345-9eeb-6646f25e471c",
    "5bb15bf6-1fdc-401c-a030-e9800b0a7d52",
    "c6f176f5-927e-4d21-aa05-835df8ffe10f",
    "ab75826e-1703-4b41-a712-ee0b441068d1",
    "0036bda7-6e0a-4baa-96ce-fd8e9b626c03",
    "b187e6ea-e01f-4696-9f50-ccd68b56bd88",
    "394a0586-efd2-42b0-be57-a071022fce3d",
    "fb79de93-314a-463b-a047-4c7d9d82a34d",
    "882a4aac-105a-4a8e-8206-d29a5de678e1",
    "42598c76-173c-4f85-a54e-d6d1cbe72c28",
    "252c8684-81d4-460b-bf2e-f4283f591903",
    "86a77a59-95af-437f-95ae-b759e2cc74ab",
    "692db126-7a51-4197-9453-f47fe7dc56ed",
    "7c5c180b-2b51-4177-b1e1-d19a4f873963",
    "7ee95880-bd8e-4f58-964c-e9bf3b2478f0",
    "b1c99f0f-dc0f-4dbd-93f8-4d528f81c3ae",
    "16413913-f454-491f-b251-98b8816b7958",
    "602607bd-ffb1-4e06-9f06-1b6ef871ed70",
    "8965440d-dd54-4d4c-a782-6c8f7b09593c",
    "b0d71338-33fd-48c1-9413-89106cbf37de",
    "7ee28436-03a3-42dd-bd9c-86455933c610",
    "ac68fe7e-aae8-4799-a71c-fc52de99ae11",
    "477c837f-c40c-4566-a964-26948a56795c",
    "ceee7695-f0ad-4774-a20d-ec02d11a2208",
    "35564abf-885b-4b34-acbe-bc58bc9311e5",
    "d9bc475b-3140-43e2-98f1-5e14d5b6c39e",
    "6d8bba56-c120-4313-9273-04d50a9bd6f2",
    "25a1203b-cf95-4e52-98ff-8e3c56bbcc83",
    "5d2b647e-b5bf-49c1-b4fe-68f463f78e1c",
    "49e695ca-29d8-4dbc-bb69-36e4e89c95c9",
    "3a1ef959-9159-466d-95bd-26493f5fcb31",
    "eb8a5d29-1584-401f-9f04-eaf1b6d1787c",
    "8272a6d9-8c3f-4a53-932a-d89cebc8e063",
    "7fe1c10a-45dc-4ed3-8237-c719a1317f90",
    "acbddf69-09bc-463b-ae99-fc248e639132",
    "6e4cead2-aad7-403d-9690-da53c40a858b",
    "0bb40de9-6c79-454e-9323-9a188dd3df76",
    "e38a0ea8-0e89-4534-8bf3-157fd91c5ed8",
    "0806cac5-daf4-46b5-beb1-ad5024234204",
    "5c11462c-0277-4f55-b91b-2f05f3bb783d",
    "6bb70242-7d0a-42b0-879e-3abac50b9141",
    "8a9480d7-9cc2-4058-b253-ca05e3e5ccfa",
    "55eb232a-1b58-4b45-b05b-796743241905",
    "ad4fe10d-4038-4e50-bb04-05d7fe3cf819",
    "399d0c9c-8a88-4f26-81dd-0ad93af33a1a",
    "b0e4b42b-0c77-4fee-860b-cb54852fb241",
    "bafbac36-7b93-494d-a406-e323c22eb319",
    "79afe600-6a54-42cc-8f2d-2d315facb764",
    "d523af39-e0e7-4952-9d23-3d4dd467081d",
    "3c1f7d48-d178-4d49-8a3b-5ae033c57946",
    "163b2311-0409-48d3-985f-1a76c85fddf5",
    "a2dc7df3-88fc-4ff1-aa45-47048055c8de",
    "7ab81f4a-abfc-4634-8339-438dc2225adc",
    "dc8eda0b-e19d-40e1-9215-4bf82c20f874",
    "8f7aeabe-6dda-4ac8-8c09-d97ab8d44625",
    "9ad9b0c2-c0c8-4754-9bde-724bcd16bdd5",
    "082bdd41-005d-43c5-a5bd-f8f9628534fb",
    "19a025a5-5ccf-40d2-9607-1f444ca2ccec",
    "870a99aa-e1ce-42cd-84b8-fb20d9e88b19",
    "8d483faa-2971-48fb-9bd7-c6d7f3d0924d",
    "f2ba889a-ad04-43f1-9ec8-30a6d2fb36f5",
    "6c2094fe-edc5-40c7-a1e3-a70317eef020",
    "a2a27a8d-0afb-4d1c-b565-96ff845544d0",
    "62f8c983-5ad9-4e45-8766-b94ff7e866a7",
    "66759278-b6b9-4e33-b674-2081b6a16403",
    "228ebb33-3272-4242-a078-41c0fcc12b5b",
    "98c07703-051c-4891-aaa3-0650ccf5668c",
    "f59d3086-2887-4d0e-b8ab-f45418072803",
    "63e01002-c601-499f-a5e9-46eb602d0c51",
    "d1d2db51-9557-4f56-88d9-30ad1b41272b",
    "5f9d5de8-c086-424d-a6b9-581792c83f5e",
    "77f26f85-32ad-4906-9dcc-8f3e3762ca85",
    "ced1fb2c-f453-4dec-9fef-f6e0e06080ff",
    "bd64ea43-05ac-4283-8a08-1b9b2becdaf9",
    "23acc9bf-e66d-46fe-b247-c457591a0a84",
    "d4e8d458-ac1b-4d10-b444-22048093e23d",
    "c8974d65-919f-4bae-b61b-5e9c1b16649d",
    "ab3e0454-3de7-4e4e-be2d-f42a2193473b",
    "f70a823b-5f9b-4dc2-a57b-7f9e30a08dd4",
    "a5ea9cf9-c9e9-4d3f-8469-db49cf78429c",
    "ea90f3b2-0d60-43b7-84d1-08635b50dbb8",
    "39bdcdff-35d9-45b5-8634-7db901e92ce0",
    "c4bcbec2-1b9b-4008-a09f-039f2ce8f289",
    "eed82b17-4600-4700-8d66-401c45e07e0e",
    "5333f719-beab-4d99-a55b-fff9ef3a60e2",
    "72642998-ffa5-4aba-876e-f66d7429f469",
    "4d65438f-c0f0-4cd4-9ee3-a92dc0be5916",
    "91795614-edfd-4659-bd0c-ff688e16269f",
    "e096d44f-4593-46e6-b4ca-db90acd75986",
    "53e14506-3b82-4270-8642-6cddc6c45689",
    "42472ab1-8781-41f6-8ed8-8e8b78dfa5e7",
    "8f87e33a-960a-4cf4-9d8f-3b4834933755",
    "6a8a1383-a721-4136-a149-a8c03f06a176",
    "a76118bd-94aa-4f7e-80ea-77beaf629ae8",
  ];
  return users.some((user) => user === id);
};

export const convertDescriptionToSlug = (description: string) =>
  description
    .toLowerCase()
    .replace(/[éèêë]/gm, "e")
    .replace(/[àâä]/gm, "a")
    .replace(/[ç]/gm, "c")
    .replace(/[ùûü]/gm, "u")
    .replace(/[ôö]/gm, "o")
    .replace(/[ïî]/gm, "i")
    .replace(/[_]/gm, " ")
    .replace(/[^\w ]+/g, "")
    .replace(/ +/g, "-")
    .replace(/^-/g, "");

export const priceChecker = (price: any, reducedPrice: any): boolean => {
  if (!price) return false;
  if (!reducedPrice) return true;
  if (typeof price !== "number") price = parseInt(price);
  if (typeof reducedPrice !== "number") reducedPrice = parseInt(reducedPrice);
  return price > reducedPrice;
};

export const checkUserCanCreateOrderWithoutProduct = (id: string) => {
  return (
    id === "82bc5c9b-c65d-46ea-b426-c1237d83238e" ||
    id === "12895c73-990c-475b-8df6-533eaa9ea501" ||
    id === "3a2855aa-9319-481e-ac50-164083b8a297"
  );
};

export const getParamsFromUrl = (param: string) => {
  let vars: { [key: string]: string } = {};
  window.location.href
    .replace(window.location.hash, "")
    .replace(/[?&]+([^=&]+)=?([^&]*)?/gi, (m, key, value) => {
      vars[key] = value ?? "";
      return "";
    });
  if (param) {
    return vars[param] ?? null;
  }
  return vars;
};

export const checkPhoneNumberValidity = (phoneNumber: string) => {
  const phoneNumberRegex = new RegExp(
    `^((0|\\+213)((5|6|7)(\\d){8}|(49|27|29|32|33|34|25|26|37|43|46|21|23|36|48|38|31|45|35|41|24)(\\d){6}))|((\\+216)?(((2|5|6|7|9)\\d)|(46))(\\d){6})$`
  );
  return phoneNumberRegex.test(phoneNumber);
};

export const getUnfCountFilters = (filter: string) => {
  return [
    getApprovedUnfFilter(filter),
    getCancelledUnfFilter(filter),
    getCallbackUnfFilter(filter),
    getPostponedUnfFilter(filter),
  ];
};

const getApprovedUnfFilter = (filter: string) => {
  const urlParams = new URLSearchParams(filter);
  urlParams.set("status", "1");
  urlParams.delete("is_callback");
  urlParams.delete("postponed");
  return "?" + urlParams.toString();
};

const getCancelledUnfFilter = (filter: string) => {
  const urlParams = new URLSearchParams(filter);
  urlParams.set("status", "2");
  urlParams.delete("is_callback");
  urlParams.delete("postponed");
  return "?" + urlParams.toString();
};

const getCallbackUnfFilter = (filter: string) => {
  const urlParams = new URLSearchParams(filter);
  urlParams.set("status", "0");
  urlParams.set("is_callback", "True");
  urlParams.delete("postponed");
  return "?" + urlParams.toString();
};

const getPostponedUnfFilter = (filter: string) => {
  const urlParams = new URLSearchParams(filter);
  urlParams.set("status", "0");
  urlParams.delete("is_callback");
  urlParams.set("postponed", "True");
  return "?" + urlParams.toString();
};
