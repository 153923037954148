import { userConstants } from "../constants";
import { RequestService, FunctionService } from "../services";
import Cookies from "js-cookie";
const token = Cookies.get("auth");
const store_id = Cookies.get("c_store");
export const userActions = {
  Login,
  Register,
  RegisterTap,
  Logout,
  GetProfileUser,
  UpdateStore,
  CreateTeam,
  UpdateTeam,
  ListTeam,
  ListMemberTeam,
  InvitMember,
  VerifyMember,
  CreateMember,
  UpdatePicture,
  DeleteTeam,
  GetStoreInfo,
  resetSms,
  resetPassword,
  UpdatePictureUser,
  ChangePassword,
  setAsCanary,
  CheckUsernameRegister,
  CheckPhoneRegister,
  CheckStoreNameRegister,
};

function resetPassword(user) {
  return (dispatch) => {
    dispatch(FunctionService.request(userConstants.RESET_PASSWORD_REQUEST));
    RequestService.PostRequest("stores/reset/", user, {})
      .then((res) => {
        dispatch(
          FunctionService.succes(res.data, userConstants.RESET_PASSWORD_SUCCESS)
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(err, userConstants.RESET_PASSWORD_FAILURE)
        );
      });
  };
}

function ChangePassword(user) {
  return (dispatch) => {
    dispatch(FunctionService.request(userConstants.CHANGE_PASSWORD_REQUEST));
    RequestService.PostRequest("users/update_password/", user, {
      Authorization: "Token " + token,
    })
      .then((res) => {
        dispatch(
          FunctionService.succes(
            res.data,
            userConstants.CHANGE_PASSWORD_SUCCESS
          )
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(
            err.response.data,
            userConstants.CHANGE_PASSWORD_FAILURE
          )
        );
      });
  };
}

function resetSms(phone) {
  return (dispatch) => {
    dispatch(FunctionService.request(userConstants.RESET_SMS_REQUEST));
    RequestService.PostRequest("stores/sms_reset/", phone, {})
      .then((res) => {
        dispatch(
          FunctionService.succes(res.data, userConstants.RESET_SMS_SUCCESS)
        );
      })
      .catch((err) => {
        dispatch(FunctionService.failure(err, userConstants.RESET_SMS_FAILURE));
      });
  };
}

function Login(user) {
  return (dispatch) => {
    dispatch(FunctionService.request(userConstants.LOGIN_REQUEST));
    RequestService.PostRequest("store/auth/", user, {})
      .then((res) => {
        dispatch(FunctionService.succes(res.data, userConstants.LOGIN_SUCCESS));
      })
      .catch((err) => {
        dispatch(FunctionService.failure(err, userConstants.LOGIN_FAILURE));
      });
  };
}

function RegisterTap(user) {
  return (dispatch) => {
    dispatch(FunctionService.succes(user, userConstants.REGISTER_SENTUSER));
  };
}
function Register(user) {
  return (dispatch) => {
    dispatch(FunctionService.request(userConstants.REGISTER_REQUEST));
    RequestService.PostRequest("shared/store/", user, {})
      .then((res) => {
        dispatch(
          FunctionService.succes(res.data, userConstants.REGISTER_SUCCESS)
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure({ err, user }, userConstants.REGISTER_FAILURE)
        );
      });
  };
}
function Logout() {
  return { type: userConstants.LOGOUT };
}

/*$--------------$*/

function CreateTeam(data) {
  return (dispatch) => {
    dispatch(FunctionService.request(userConstants.CREATE_TEAM_REQUEST));
    RequestService.PostRequest("stores/team_store/", data, {
      Authorization: "Token " + token,
    })
      .then((res) => {
        dispatch(
          FunctionService.succes(res, userConstants.CREATE_TEAM_SUCCESS)
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(err, userConstants.CREATE_TEAM_FAILURE)
        );
      });
  };
}

function UpdatePicture(formdata) {
  return (dispatch) => {
    dispatch(FunctionService.request(userConstants.UPLOAD_PICTURE_REQUEST));
    RequestService.PostRequest("stores/profile_picture/store", formdata, {
      Authorization: "Token " + token,
    })
      .then((res) => {
        dispatch(
          FunctionService.succes(res.data, userConstants.UPLOAD_PICTURE_SUCCESS)
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(err, userConstants.UPLOAD_PICTURE_FAILURE)
        );
      });
  };
}

function UpdatePictureUser(formdata) {
  return (dispatch) => {
    dispatch(
      FunctionService.request(userConstants.UPLOAD_USER_PICTURE_REQUEST)
    );
    RequestService.PostRequest("users/profile_picture/", formdata, {
      Authorization: "Token " + token,
    })
      .then((res) => {
        dispatch(
          FunctionService.succes(
            res.data,
            userConstants.UPLOAD_USER_PICTURE_SUCCESS
          )
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(
            err,
            userConstants.UPLOAD_USER_PICTURE_FAILURE
          )
        );
      });
  };
}

function UpdateTeam(data, idteam) {
  return (dispatch) => {
    dispatch(FunctionService.request(userConstants.UPDATE_TEAM_REQUEST));
    RequestService.PutRequest("stores/team_store/" + idteam + "/", data, {
      Authorization: "Token " + token,
    })
      .then((res) => {
        dispatch(
          FunctionService.succes(res, userConstants.UPDATE_TEAM_SUCCESS)
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(err, userConstants.UPDATE_TEAM_FAILURE)
        );
      });
  };
}

function ListMemberTeam(idteam) {
  return (dispatch) => {
    dispatch(FunctionService.request(userConstants.GET_TEAM_DETAIL_REQUEST));
    RequestService.GetRequest("stores/team_store/team_member/?team=" + idteam, {
      Authorization: "Token " + token,
    })
      .then((res) => {
        dispatch(
          FunctionService.succes(
            res.data,
            userConstants.GET_TEAM_DETAIL_SUCCESS
          )
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(err, userConstants.GET_TEAM_DETAIL_FAILURE)
        );
      });
  };
}

function ListTeam() {
  return (dispatch) => {
    dispatch(FunctionService.request(userConstants.LIST_TEAM_REQUEST));
    RequestService.GetRequest("stores/team_store/", {
      Authorization: "Token " + token,
    })
      .then((res) => {
        dispatch(
          FunctionService.succes(res.data, userConstants.LIST_TEAM_SUCCESS)
        );
      })
      .catch((err) => {
        dispatch(FunctionService.failure(err, userConstants.LIST_TEAM_FAILURE));
      });
  };
}

function DeleteTeam(idTeam) {
  return (dispatch) => {
    dispatch(FunctionService.request(userConstants.DELETE_TEAM_REQUEST));
    RequestService.DeleteRequest("stores/team_store/" + idTeam + "/", {
      Authorization: "Token " + token,
    })
      .then((res) => {
        dispatch(
          FunctionService.succes(res.data, userConstants.DELETE_TEAM_SUCCESS)
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(err, userConstants.DELETE_TEAM_FAILURE)
        );
      });
  };
}

function InvitMember(data) {
  return (dispatch) => {
    dispatch(FunctionService.request(userConstants.INVITE_MEMBER_REQUEST));
    RequestService.PostRequest("stores/team_store/invite/", data, {
      Authorization: "Token " + token,
    })
      .then((res) => {
        dispatch(
          FunctionService.succes(res.data, userConstants.INVITE_MEMBER_SUCCESS)
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(err, userConstants.INVITE_MEMBER_FAILURE)
        );
      });
  };
}

function VerifyMember(data) {
  return (dispatch) => {
    dispatch(FunctionService.request(userConstants.VERIFY_MEMBER_REQUEST));
    RequestService.PostRequest("stores/verify/", data)
      .then((res) => {
        dispatch(
          FunctionService.succes(res.data, userConstants.VERIFY_MEMBER_SUCCESS)
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(
            err.response,
            userConstants.VERIFY_MEMBER_FAILURE
          )
        );
      });
  };
}

function CreateMember(data) {
  return (dispatch) => {
    dispatch(FunctionService.request(userConstants.CREATE_MEMBER_REQUEST));
    RequestService.PostRequest("stores/store_member/", data)
      .then((res) => {
        dispatch(
          FunctionService.succes(res.data, userConstants.CREATE_MEMBER_SUCCESS)
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(err, userConstants.CREATE_MEMBER_FAILURE)
        );
      });
  };
}
/******************* */
function UpdateStore(user) {
  return (dispatch) => {
    dispatch(FunctionService.request(userConstants.UPDATE_STORE_REQUEST));
    RequestService.PatchRequest("shared/store/" + store_id + "/", user, {
      Authorization: "Token " + token,
    })
      .then((res) => {
        dispatch(
          FunctionService.succes(res.data, userConstants.UPDATE_STORE_SUCCESS)
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(
            err.response,
            userConstants.UPDATE_STORE_FAILURE
          )
        );
      });
  };
}
function GetProfileUser() {
  return (dispatch) => {
    dispatch(FunctionService.request(userConstants.GET_USER_REQUEST));
    RequestService.GetRequest("stores/user_store/", {
      Authorization: "Token " + token,
    })
      .then((res) => {
        let resltProfile = {};
        if (res.data) {
          if (res.data.user_info && res.data.user_info.username)
            resltProfile.username = res.data.user_info.username;
          if (res.data.user_info) resltProfile.email = res.data.user_info.email;
          if (res.data.user_info) resltProfile.phone = res.data.user_info.phone;
          resltProfile.intgraed = res.data.user_info.integration;
          resltProfile.is_store = res.data.is_store;
          resltProfile.picture = res.data.user_info.picture;
          Array.isArray(res.data.user_info.groups[0].permissions) &&
            res.data.user_info.groups[0].permissions.map((prem) => {
              resltProfile[prem.codename] = true;
            });
          resltProfile.full_name = res.data.user_info.full_name;
        }
        dispatch(
          FunctionService.succes(resltProfile, userConstants.GET_USER_SUCCESS)
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(err.response, userConstants.GET_USER_FAILURE)
        );
      });
  };
}
function GetStoreInfo() {
  return (dispatch) => {
    dispatch(FunctionService.request(userConstants.GET_STOREINFO_REQUEST));
    RequestService.GetRequest("stores/profile/", {
      Authorization: "Token " + token,
    })
      .then((res) => {
        dispatch(
          FunctionService.succes(
            res.data.information,
            userConstants.GET_STOREINFO_SUCCESS
          )
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(
            err.response,
            userConstants.GET_STOREINFO_FAILURE
          )
        );
      });
  };
}
function setAsCanary() {
  return (dispatch) => {
    dispatch({
      type: userConstants.SET_IS_CANARY,
    });
  };
}

function CheckUsernameRegister(username) {
  return (dispatch) => {
    dispatch(FunctionService.request(userConstants.CHECK_USERNAME_REQUEST));
    RequestService.GetRequest(
      "authentification/users/check-username/" + username + "/"
    )
      .then((res) => {
        dispatch(
          FunctionService.succes(res.data, userConstants.CHECK_USERNAME_SUCCESS)
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(err, userConstants.CHECK_USERNAME_FAILURE)
        );
      });
  };
}

function CheckPhoneRegister(phone) {
  return (dispatch) => {
    dispatch(FunctionService.request(userConstants.CHECK_PHONE_REQUEST));
    RequestService.GetRequest("delivery/stores/check-phone/" + phone + "/")
      .then((res) => {
        dispatch(
          FunctionService.succes(res.data, userConstants.CHECK_PHONE_SUCCESS)
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(err, userConstants.CHECK_PHONE_FAILURE)
        );
      });
  };
}

function CheckStoreNameRegister(name) {
  return (dispatch) => {
    dispatch(FunctionService.request(userConstants.CHECK_NAME_STORE_REQUEST));
    RequestService.GetRequest("delivery/stores/check-name/" + name + "/")
      .then((res) => {
        dispatch(
          FunctionService.succes(
            res.data,
            userConstants.CHECK_NAME_STORE_SUCCESS
          )
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(err, userConstants.CHECK_NAME_STORE_FAILURE)
        );
      });
  };
}
