import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import ContainerPage from "../_Common/ContainerPage";
import { Translate } from "../../../utils/lang/translate";
import { ordersActions, productActions } from "../../../actions";
import SelectFormSearch from "../../_Common/SelectFormSearch";
import Loader from "../../_Common/Loader";
import BtnLg from "../_Common/BtnLg";
import { Link } from "react-router-dom";
import Helmet from "react-helmet";
import XLSX from "xlsx";
class ImportOrderPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      DataTable: null,
      prodcut: "",
      errPrd: "",
      radio: "",
      not_created: ["Rania bakalam / 0555101010"],
    };
    this.submit = false;
    this.readxlsfile = this.readxlsfile.bind(this);
    this.blockEnter = this.blockEnter.bind(this);
    this.dropFile = this.dropFile.bind(this);
  }
  componentDidMount() {
    document.title = "Maystro Delivrey - " + Translate("titles", "importexcel");
    if (this.props.ProductFinded !== "2") {
      this.props.GetAllProduct();
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { DataImport, ImportingOrd } = nextProps;
    if (ImportingOrd === "1" && this.submit) {
      this.submit = false;
      this.setState({
        radio: DataImport.data.ratio_created + "%",
        DataTable: null,
      });
    }
  }
  showDropHigh(e) {
    e.preventDefault();
    e.stopPropagation();
    var dropIteam = document.getElementById("Drag_Drop_Iteam");
    dropIteam.classList.remove("hidElem");
  }
  hideDropHigh(e) {
    e.preventDefault();
    e.stopPropagation();
    var dropIteam = document.getElementById("Drag_Drop_Iteam");
    dropIteam.classList.add("hidElem");
  }
  dropFile(e) {
    e.preventDefault();
    e.stopPropagation();
    var dropIteam = document.getElementById("Drag_Drop_Iteam");
    dropIteam.classList.add("hidElem");
    let dataTra = e.dataTransfer;
    this.readxlsfile({ target: dataTra });
  }
  readxlsfile(e) {
    var reader = new FileReader();
    var bindthis = this;
    reader.readAsArrayBuffer(e.target.files[0]);
    reader.onload = function () {
      var data = new Uint8Array(reader.result);
      var arr = [];
      for (var i = 0; i !== data.length; ++i)
        arr[i] = String.fromCharCode(data[i]);
      var DataBook = arr.join("");
      var workbook = XLSX.read(DataBook, {
        type: "binary",
      });
      var first_sheet_name = workbook.SheetNames[0];

      var worksheet = workbook.Sheets[first_sheet_name];
      var JsonData = XLSX.utils.sheet_to_json(worksheet);
      bindthis.setState({
        DataTable: JsonData,
      });
    };
  }
  handleChangeProduct(e) {
    this.setState({
      prodcut: e.target.value,
    });
  }
  ClearData() {
    this.setState({
      DataTable: null,
    });
  }
  SendOrders() {
    var TableData = document.getElementById("TbImpBo");
    const { data } = this.props;
    if (data && data.list && TableData && TableData.rows) {
      let idproduct = data.list.find(
        (product) => product.logistical_description === this.state.prodcut
      );
      if (idproduct) {
        this.setState({
          errPrd: "",
        });
        var product = idproduct.id;
        let len = TableData.rows.length;
        var rows = TableData.rows;
        let Dataorders = [];
        for (var i = 0; i < len; i++) {
          let row = rows[i];
          let order = {
            destination_text: row.cells[3].textContent,
            product_price: row.cells[6].textContent
              ? parseInt(row.cells[6].textContent.replace(/\D/g, ""))
              : 0,
            customer_name: row.cells[1].textContent,
            customer_phone: row.cells[2].textContent,
            express:
              row.cells[7].textContent === "oui" ||
              row.cells[7].textContent === "yes",
            wilaya: row.cells[4].textContent,
            commune: row.cells[5].textContent,
            quantity: row.cells[0].textContent,
            type_de_livraison: row.cells[9].textContent,
          };
          if (row.cells[8].textContent) {
            order.note_to_driver = row.cells[8].textContent;
          }
          order.source = 1;
          Dataorders.push(order);
        }
        this.submit = true;
        this.props.ImportOrder({ orders: Dataorders }, product);
      } else {
        this.setState({
          errPrd: Translate("error", "eo8"),
        });
      }
    }
  }
  blockEnter(e) {
    var keyCode = e.which || e.keyCode;
    keyCode === 13 && e.preventDefault();
  }
  render() {
    const { ImportingOrd, DataImport, data } = this.props;
    let styleDrop = (
      <style>
        {`.StBefoCn::before{
            content: "` +
          Translate("importorder", "dropher") +
          `";
            font-size: 1.7em;
            font-weight: bold;
            color:var(--fntClrInv);
        }`}
      </style>
    );
    return (
      <ContainerPage page_title={Translate("titles", "importexcel")}>
        <div className="ClSidTh StBrdTb SecnNivWid StBgbrds RlPs ">
          {ImportingOrd === "0" && <Loader />}
          {!Array.isArray(this.state.DataTable) ? (
            <Fragment>
              <div
                id="Cont_File_Loader"
                style={{ padding: "50px 20px" }}
                className="BrdBlDtd RlPs"
                onDrop={this.dropFile}
                onDragLeave={this.hideDropHigh}
                onDragEnter={this.showDropHigh}
                onDragOver={this.showDropHigh}
              >
                <div id="Drag_Drop_Iteam" className="hidElem">
                  <Helmet>{styleDrop}</Helmet>
                  <div
                    className="StAbs FlHg FlWd StTpNl StRgNl"
                    style={{ zIndex: "1" }}
                  >
                    <div className="RlPs InFlx FlWd FlHg AlgnItm JstfCnt StbdGTrn StBefoCn"></div>
                  </div>
                </div>
                <form>
                  <div className="InFlx AlgnItm  flxDrc responsFlx spcBtwn">
                    <div className="TxtCn RlPs">
                      <input
                        type="file"
                        id="File_Import_excel"
                        onChange={this.readxlsfile}
                        className="hidElem HdOvrfl StAbs"
                      />
                      <label
                        htmlFor="File_Import_excel"
                        className="BdgBlcl DsBlc stbold CrsPoi StBrdRd StPdsml2"
                      >
                        {Translate("importorder", "filetext")}
                      </label>
                      <span style={{ paddingTop: "8px" }} className="DsBlc">
                        {Translate("importorder", "accpfil")}
                      </span>
                    </div>
                    <div className="RlPs" style={{ maxWidth: "65%" }}>
                      {this.props.dtStoreinfo.country.name === "Algeria" ? (
                        <p>
                          {Translate("importorder", "txtfil")}
                          <a
                            className="StBle"
                            href="/excel_model_add_order.xlsx"
                          >
                            {Translate("importorder", "modldown")}
                          </a>
                        </p>
                      ) : (
                        <p>
                          {Translate("importorder", "txtfil")}
                          <a
                            className="StBle"
                            href="/excel_model_add_order_tunisia.xlsx"
                          >
                            {Translate("importorder", "modldown")}
                          </a>
                        </p>
                      )}
                    </div>
                  </div>
                </form>
              </div>
              {this.state.radio && (
                <div className="TxtCn">
                  <h3 className="">
                    {Translate("importorder", "avrgcrea") +
                      " " +
                      this.state.radio}
                  </h3>
                  <p>
                    {Translate("importorder", "youhavecr") +
                      " " +
                      this.state.radio +
                      " " +
                      Translate("importorder", "plsgoto")}{" "}
                  </p>
                  {DataImport.data &&
                    DataImport.data.not_created &&
                    DataImport.data.not_created.length > 0 && (
                      <div style={{ margin: "5%" }}>
                        <h3 className="StRedClr">
                          {Translate("importorder", "followingfailed")}
                        </h3>
                        {DataImport.data.not_created.map((order) => (
                          <div className="lytWdp">{order}</div>
                        ))}
                      </div>
                    )}
                  <Link to="/orders">
                    <div className="RlPs StClSvgD StwdMaxC CrsPoi stbold MrAot">
                      <BtnLg
                        classSet={"BrdBl BdgGc"}
                        style={{ padding: "0px 30px" }}
                        text={Translate("importorder", "gotoorder")}
                      />
                    </div>
                  </Link>
                </div>
              )}
            </Fragment>
          ) : (
            <Fragment>
              <div className="StLftAut" style={{ width: "60%" }}>
                <SelectFormSearch
                  id={"productname"}
                  name={"productname"}
                  index={"logistical_description"}
                  option={data ? data.list : ""}
                  text={
                    <h3 className="DlMg">
                      {Translate("product", "productname")}
                    </h3>
                  }
                  workfun={this.handleChangeProduct.bind(this)}
                  placeholder={Translate("product", "productname")}
                  error={this.state.errPrd}
                />
              </div>
              <div className="FlWd StdirLn">
                <h3 className="DlMg">{Translate("importorder", "ordlist")}</h3>
                <p className="DlMg StOpcVal">
                  {Translate("importorder", "youcanedit")}
                </p>
              </div>
              <div
                id="ResultTable"
                className="SmMarS1"
                style={{ overflow: "auto" }}
              >
                <table className="FlWd HdOvrfl StDrc">
                  <thead className="FlWd StSmlS FrBdEl Fntclinv BdgTra2">
                    <tr>
                      <td className="borderClr">
                        {Translate("orders", "quantity")}
                      </td>
                      <td className="borderClr">
                        {Translate("orders", "customername")}
                      </td>
                      <td className="borderClr">
                        {Translate("orders", "customerphonenumber")}
                      </td>
                      <td className="borderClr">
                        {Translate("orders", "adresse")}
                      </td>
                      <td className="borderClr">
                        {Translate("orders", "wilaya")}
                      </td>
                      <td className="borderClr">
                        {Translate("orders", "district")}
                      </td>
                      <td className="borderClr">
                        {Translate("orders", "totalprice")}
                      </td>
                      <td className="borderClr">
                        {Translate("orders", "expressdelivery")}
                      </td>
                      <td className="borderClr">
                        {Translate("orders", "notetodriver")}
                      </td>
                      <td className="borderClr">
                        {Translate("orders", "dekiverytype")}
                      </td>
                    </tr>
                  </thead>
                  <tbody
                    id="TbImpBo"
                    className="FlWd StSmlS FrBdEl Fntclinv BdgTra2"
                  >
                    {this.state.DataTable.map((element, key) => {
                      return (
                        <tr key={key}>
                          <td
                            className="borderClr"
                            onKeyPress={this.blockEnter}
                            contentEditable
                            suppressContentEditableWarning={true}
                          >
                            {element["Quantity"]}
                          </td>
                          <td
                            className="borderClr"
                            onKeyPress={this.blockEnter}
                            contentEditable
                            suppressContentEditableWarning={true}
                          >
                            {element["Nom_du_client"]}
                          </td>
                          <td
                            className="borderClr"
                            onKeyPress={this.blockEnter}
                            contentEditable
                            suppressContentEditableWarning={true}
                          >
                            {element["Téléphone_du_client"]}
                          </td>
                          <td
                            className="borderClr"
                            onKeyPress={this.blockEnter}
                            contentEditable
                            suppressContentEditableWarning={true}
                          >
                            {element["Adresse_de_livraison"]}
                          </td>
                          <td
                            className="borderClr"
                            onKeyPress={this.blockEnter}
                            contentEditable
                            suppressContentEditableWarning={true}
                          >
                            {element["wilaya"]}
                          </td>
                          <td
                            className="borderClr"
                            onKeyPress={this.blockEnter}
                            contentEditable
                            suppressContentEditableWarning={true}
                          >
                            {element["commune"]}
                          </td>
                          <td
                            className="borderClr"
                            onKeyPress={this.blockEnter}
                            contentEditable
                            suppressContentEditableWarning={true}
                          >
                            {element["Prix_total"]}
                          </td>
                          <td
                            className="borderClr"
                            onKeyPress={this.blockEnter}
                            contentEditable
                            suppressContentEditableWarning={true}
                          >
                            {element["livraison_express"]}
                          </td>
                          <td
                            className="borderClr"
                            onKeyPress={this.blockEnter}
                            contentEditable
                            suppressContentEditableWarning={true}
                          >
                            {element["Note_au_livreur"]}
                          </td>
                          <td
                            className="borderClr"
                            onKeyPress={this.blockEnter}
                            contentEditable
                            suppressContentEditableWarning={true}
                          >
                            {element["type_de_livraison"]}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="InFlx flxDrc responsFlx" style={{ gap: "3vw" }}>
                <div
                  className="StClSvgD CrsPoi stbold"
                  onClick={this.ClearData.bind(this)}
                >
                  <BtnLg
                    classSet={"BrdBl BdgGc"}
                    style={{ padding: "0 30px" }}
                    text={Translate("importorder", "goback")}
                  />
                </div>
                <div
                  className="StAutMr StClSvgD CrsPoi stbold"
                  onClick={this.SendOrders.bind(this)}
                >
                  <BtnLg
                    style={{ padding: "0 30px" }}
                    text={Translate("importorder", "confirm")}
                  />
                </div>
              </div>
            </Fragment>
          )}
        </div>
      </ContainerPage>
    );
  }
}
function mapState(state) {
  const { ImportingOrd, DataImport } = state.orders;
  const { ProductFinded, data } = state.product;
  const { dtStoreinfo } = state.user;
  return { ImportingOrd, DataImport, ProductFinded, data, dtStoreinfo };
}
const actionCreators = {
  ImportOrder: ordersActions.ImportOrder,
  GetAllProduct: productActions.GetAllProduct,
};

const connectedImportOrderPage = connect(
  mapState,
  actionCreators
)(ImportOrderPage);
export { connectedImportOrderPage as ImportOrderPage };
