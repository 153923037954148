import { userConstants } from "../constants";

export function user(state, action) {
  switch (action.type) {
    case userConstants.GET_USER_REQUEST:
      return {
        ...state,
        GetingUser: "0",
        dataProfile: action.payload,
      };
    case userConstants.GET_USER_SUCCESS:
      return {
        ...state,
        GetingUser: "1",
        dataProfile: action.payload,
      };
    case userConstants.GET_USER_FAILURE:
      return {
        ...state,
        GetingUser: "2",
        dataProfile: action.payload,
      };
    case userConstants.UPDATE_STORE_REQUEST:
      return {
        ...state,
        UpdatingStore: "0",
        dataUpdate: action.payload,
      };
    case userConstants.UPDATE_STORE_SUCCESS:
      return {
        ...state,
        UpdatingStore: "1",
        dataUpdate: action.payload,
      };
    case userConstants.UPDATE_STORE_FAILURE:
      return {
        ...state,
        UpdatingStore: "2",
        dataUpdate: action.payload,
      };
    case userConstants.UPLOAD_PICTURE_REQUEST:
      return {
        ...state,
        UpdatingPicture: "0",
        dataPicture: action.payload,
      };
    case userConstants.UPLOAD_PICTURE_SUCCESS:
      return {
        ...state,
        UpdatingPicture: "1",
        dataPicture: action.payload,
      };
    case userConstants.UPLOAD_PICTURE_FAILURE:
      return {
        ...state,
        UpdatingPicture: "2",
        dataPicture: action.payload,
      };

    case userConstants.UPLOAD_USER_PICTURE_REQUEST:
      return {
        ...state,
        UpdatingPic: "0",
        dataPicture: action.payload,
      };
    case userConstants.UPLOAD_USER_PICTURE_SUCCESS:
      return {
        ...state,
        UpdatingPic: "1",
        dataPicture: action.payload,
      };
    case userConstants.UPLOAD_USER_PICTURE_FAILURE:
      return {
        ...state,
        UpdatingPic: "2",
        dataPicture: action.payload,
      };
    case userConstants.GET_STOREINFO_REQUEST:
      return {
        ...state,
        gtStoreInf: "0",
        dtStoreinfo: action.payload,
      };
    case userConstants.GET_STOREINFO_SUCCESS:
      return {
        ...state,
        gtStoreInf: "1",
        dtStoreinfo: action.payload,
      };
    case userConstants.GET_STOREINFO_FAILURE:
      return {
        ...state,
        gtStoreInf: "2",
        dtStoreinfo: action.payload,
      };
    case userConstants.CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        chngPass: "0",
        chngPassData: action.payload,
      };
    case userConstants.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        chngPass: "1",
        chngPassData: action.payload,
      };
    case userConstants.CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        chngPass: "2",
        chngPassData: action.payload,
      };
    case userConstants.CHECK_USERNAME_REQUEST:
      return {
        ...state,
        checkingusername: "0",
        usernamedata: action.payload,
      };
    case userConstants.CHECK_USERNAME_SUCCESS:
      return {
        ...state,
        checkingusername: "1",
        usernamedata: action.payload,
      };
    case userConstants.CHECK_USERNAME_FAILURE:
      return {
        ...state,
        checkingusername: "2",
        usernamedata: action.payload,
      };
    case userConstants.CHECK_PHONE_REQUEST:
      return {
        ...state,
        checkingphone: "0",
        phonedata: action.payload,
      };
    case userConstants.CHECK_PHONE_SUCCESS:
      return {
        ...state,
        checkingphone: "1",
        phonedata: action.payload,
      };
    case userConstants.CHECK_PHONE_FAILURE:
      return {
        ...state,
        checkingphone: "2",
        phonedata: action.payload,
      };
    case userConstants.CHECK_NAME_STORE_REQUEST:
      return {
        ...state,
        checkingstorename: "0",
        storenamedata: action.payload,
      };
    case userConstants.CHECK_NAME_STORE_SUCCESS:
      return {
        ...state,
        checkingstorename: "1",
        storenamedata: action.payload,
      };
    case userConstants.CHECK_NAME_STORE_FAILURE:
      return {
        ...state,
        checkingstorename: "2",
        storenamedata: action.payload,
      };
    case userConstants.SET_IS_CANARY:
      return {
        ...state,
        dtStoreinfo: {
          ...state.dtStoreinfo,
          is_canary: true,
        },
      };
    default:
      return { ...state };
  }
}

export function memberTeam(state, action) {
  switch (action.type) {
    case userConstants.INVITE_MEMBER_REQUEST:
      return {
        InvitingMember: "0",
        dataInvitingMember: action.payload,
      };
    case userConstants.INVITE_MEMBER_SUCCESS:
      return {
        InvitingMember: "1",
        dataInvitingMember: action.payload,
      };
    case userConstants.INVITE_MEMBER_FAILURE:
      return {
        InvitingMember: "2",
        dataInvitingMember: action.payload,
      };
    case userConstants.VERIFY_MEMBER_REQUEST:
      return {
        VeryMember: "0",
        dataVerifyMember: action.payload,
      };
    case userConstants.VERIFY_MEMBER_SUCCESS:
      return {
        VeryMember: "1",
        dataVerifyMember: action.payload,
      };
    case userConstants.VERIFY_MEMBER_FAILURE:
      return {
        VeryMember: "2",
        dataVerifyMember: action.payload,
      };
    case userConstants.CREATE_MEMBER_REQUEST:
      return {
        ...state,
        CrtingMember: "0",
        dataCreateMember: action.payload,
      };
    case userConstants.CREATE_MEMBER_SUCCESS:
      return {
        ...state,
        CrtingMember: "1",
        dataCreateMember: action.payload,
      };
    case userConstants.CREATE_MEMBER_FAILURE:
      return {
        ...state,
        CrtingMember: "2",
        dataCreateMember: action.payload,
      };
    default:
      return { ...state };
  }
}

export function team(state, action) {
  switch (action.type) {
    case userConstants.CREATE_TEAM_REQUEST:
      return {
        CreatingTeam: "0",
        dataTeamCreate: action.payload,
      };
    case userConstants.CREATE_TEAM_SUCCESS:
      return {
        CreatingTeam: "1",
        dataTeamCreate: action.payload,
      };
    case userConstants.CREATE_TEAM_FAILURE:
      return {
        CreatingTeam: "2",
        dataTeamCreate: action.payload,
      };
    case userConstants.UPDATE_TEAM_REQUEST:
      return {
        UpdatingTeam: "0",
        dataTeamUpdate: action.payload,
      };
    case userConstants.UPDATE_TEAM_SUCCESS:
      return {
        UpdatingTeam: "1",
        dataTeamUpdate: action.payload,
      };
    case userConstants.UPDATE_TEAM_FAILURE:
      return {
        UpdatingTeam: "2",
        dataTeamUpdate: action.payload,
      };
    case userConstants.LIST_TEAM_REQUEST:
      return {
        GetingTeam: "0",
        dataListTeam: action.payload,
      };
    case userConstants.LIST_TEAM_SUCCESS:
      return {
        GetingTeam: "1",
        dataListTeam: action.payload,
      };
    case userConstants.LIST_TEAM_FAILURE:
      return {
        GetingTeam: "2",
        dataListTeam: action.payload,
      };
    case userConstants.GET_TEAM_DETAIL_REQUEST:
      return {
        GetingDTeam: "0",
        dataDListTeam: action.payload,
      };
    case userConstants.GET_TEAM_DETAIL_SUCCESS:
      return {
        GetingDTeam: "1",
        dataDListTeam: action.payload,
      };
    case userConstants.GET_TEAM_DETAIL_FAILURE:
      return {
        GetingDTeam: "2",
        dataDListTeam: action.payload,
      };
    case userConstants.DELETE_TEAM_REQUEST:
      return {
        ...state,
        DeletingTeam: "0",
      };
    case userConstants.DELETE_TEAM_SUCCESS:
      return {
        DeletingTeam: "1",
      };
    case userConstants.DELETE_TEAM_FAILURE:
      return {
        ...state,
        DeletingTeam: "2",
      };
    default:
      return { ...state };
  }
}
