import React, { Component, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import {
  productActions,
  ordersActions,
  exchangeActions,
  commonActions,
  alertActions,
} from "../../../actions";
import ContainerPage from "../_Common/ContainerPage";
import { FeatureLoked } from "../_Common/FeatureLoked";
import { Translate } from "../../../utils/lang/translate";
import GroupeForm from "../../_Common/GroupeForm";
import SelectForm from "../../_Common/SelectForm";
import SelectFormSearch from "../../_Common/SelectFormSearch";
import ToggleSwitch from "../../_Common/ToggleSwitch";
import Button from "../../_Common/Button";
import Loader from "../../_Common/Loader";
import { Link } from "react-router-dom";
import { Event, Select } from "../_Common";
import Cookies from "js-cookie";
import { CloseSvg, ExchangeSvg } from "../_Common/IconSvg";
import {
  CommuneInterface,
  ErrorInterface,
  OrderProductInterface,
  ProductInterface,
  SubmitOrderInterface,
  wilayaInterface,
} from "../../../types";
import {
  checkPhoneNumberValidity,
  checkUserCanCreateOrderWithoutProduct,
  getParamsFromUrl,
  whichWilayaHasPermissionToStopDesk,
} from "../../../utils/helpers";
const lang = Cookies.get("lang");

const PRODUCT_INIT: OrderProductInterface = {
  product_id: "",
  quantity: 0,
  logistical_description: "",
  defective: false,
  is_exchange: false,
};

const excludeProduct = (
  allProducts: ProductInterface[],
  excluded: OrderProductInterface
) => {
  return allProducts.filter(
    (el) => el.logistical_description !== excluded.logistical_description
  );
};

const ExchangePage = ({
  data,
  dataProfile,
  dtStoreinfo,
  OrderGeting,
  dataWilaya,
  DataCommune,
  ExchangeAdded,
  GetingDelivery,
  dataDelivery,
  OrdersList,
  ProductFinded,
  match,
  dataAddExchange,
  GetingWilaya,
  GetigCommune,
  GetOrders,
  GetCountry,
  Getwilayas,
  GetAllProduct,
  ClearStore,
  Getcommunes,
  GetDelivery,
  AddExchange,
  SendAlert,
  history,
}: any) => {
  var deliveryHome = 1;
  const [orderId, setOrderId] = useState<string | null>(null);
  const [products, setProducts] = useState([PRODUCT_INIT]);
  const [orderProducts, setOrderProducts] = useState<OrderProductInterface[]>(
    []
  );
  const [replaceProducts, setReplaceProducts] = useState<
    OrderProductInterface[][]
  >([]);
  const [extraProducts, setExtraProducts] = useState<OrderProductInterface[]>(
    []
  );
  const [commune, setCommune] = useState<CommuneInterface | null>(null);
  const [wilaya, setWilaya] = useState<wilayaInterface | null>(null);
  const [orderPrice, setOrderPrice] = useState(0);
  const [deliveryPrice, setDeliveryPrice] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [noteToDriver, setNoteToDriver] = useState("");
  const [error, setError] = useState<ErrorInterface>({});
  const [isExpressDelivery, setIsExpressDelivery] = useState(false);
  const [isNotFound, setIsNotFound] = useState(false);
  const [productList, setProductList] = useState<ProductInterface[]>([]);
  const [pageTitle, setPageTitle] = useState<JSX.Element>(
    <div>{Translate("orders", "echangeorder")}</div>
  );
  const [buttonTitle, setButtonTitle] = useState<string>(
    Translate("exchanges", "submit")
  );
  const [externalWilaya, setExternalWilaya] = useState<string | null>(null);
  const [externalCommune, setExternalCommune] = useState<number | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [customerName, setCustomerName] = useState<string | null>(null);
  const [customerPhoneNumber, setCustomerPhoneNumber] = useState<string | null>(
    null
  );
  const [address, setAddress] = useState<string | null>(null);
  const [alertMessage, setAlertMessage] = useState("");
  const [selectedOption, setSelectedOption] = useState<any | null>();
  const hasAddPermission = dataProfile.is_store || dataProfile.add_order;
  const hasEditPermission = dataProfile.is_store || dataProfile.change_order;

  useEffect(() => {
    document.title = "Maystro Delivrey - " + Translate("titles", "exchanges");
    const { idorder } = match.params;
    GetOrders("?display_id_or_external_id=" + idorder, false);
    GetCountry();
    GetAllProduct();
    return () => {
      ClearStore("CLEAR_COMMON");
    };
  }, []);

  useEffect(() => {
    if (dtStoreinfo?.country?.id) Getwilayas(dtStoreinfo.country.id);
  }, [dtStoreinfo?.country?.id]);

  useEffect(() => {
    if (commune && selectedOption) {
      GetDelivery(commune?.id, isExpressDelivery, selectedOption);
    }
  }, [commune, selectedOption]);

  useEffect(() => {
    if (wilaya && typeof wilaya[0] === "number")
      Getcommunes("?wilaya=" + wilaya[0]);
  }, [wilaya]);

  useEffect(() => {
    if (OrderGeting === "2") {
      const { idorder } = match.params;
      const orderData = OrdersList.ressuc.list.results.find(
        (elem: any) => elem.display_id === idorder
      );

      if (orderData) {
        const externalTitle = (
          <div className="StDrc">
            <Link className="StOpcVal" to={"/orders"}>
              {Translate("titles", "order")}
            </Link>
            <div className="Inlflx StWdDst">{Translate("orders", "flech")}</div>
            <div className="Inlflx">{Translate("orders", "echangeorder")}</div>
            <div className="Inlflx StWdDst">#</div>
            <div className="Inlflx">{orderData.display_id}</div>
          </div>
        );
        setExternalCommune(orderData.commune);
        setExternalWilaya(orderData.wilaya);
        setOrderId(orderData.id);
        setProducts(
          orderData.products.map((prod: OrderProductInterface) => ({
            ...prod,
            quantity: 0,
          }))
        );
        setOrderProducts(orderData.products);
        setReplaceProducts(
          orderData.products.map((_prod: OrderProductInterface) => [])
        );
        setCustomerName(orderData.customer_name);
        setCustomerPhoneNumber(orderData.customer_phone);
        setAddress(orderData.destination_text);
        setNoteToDriver(orderData.note_to_driver);
        setDeliveryPrice(orderData.price ?? 0);
        setOrderPrice(orderData.price ?? 0 + orderData.product_price);
        setIsExpressDelivery(orderData.express);
        setSelectedOption(orderData.delivery_type);
        setIsNotFound(false);
        setPageTitle(externalTitle);
        setButtonTitle(Translate("orders", "save"));
      }
    }
  }, [OrderGeting]);

  useEffect(() => {
    if (GetingDelivery === "2") setDeliveryPrice(dataDelivery.delivery_price);
  }, [GetingDelivery]);

  useEffect(() => {
    if (ProductFinded === "2") setProductList(data.list);
  }, [ProductFinded]);

  useEffect(() => {
    if (ExchangeAdded === "3" && isSubmitting) {
      document.getElementById("Container-root")?.scrollTo(0, 0);
      if (dataAddExchange?.response?.data)
        setAlertMessage(
          Translate("error", dataAddExchange.response.data.detail)
        );
      SendAlert("50", Translate("alert", "exchfail"), "a02");
      setIsSubmitting(false);
      setError({});
    }
    if (ExchangeAdded === "2" && isSubmitting) {
      GetOrders("");
      setIsSubmitting(false);
      SendAlert("41", Translate("alert", "exchadded"), "a01");
      history.push("/orders");
    }
  }, [ExchangeAdded, isSubmitting]);

  useEffect(() => {
    const wilayas = dataWilaya as wilayaInterface[] | null;
    if (externalWilaya && GetingWilaya === "1" && Array.isArray(wilayas)) {
      const wilaya = wilayas.find((wilaya) => wilaya[1] === externalWilaya);
      if (wilaya) {
        handleWilayaSelect(wilaya);
        setExternalWilaya(null);
      }
    }
  }, [externalWilaya, GetingWilaya]);

  useEffect(() => {
    const communes = DataCommune as CommuneInterface[] | null;
    if (externalCommune && GetigCommune === "1" && Array.isArray(communes)) {
      const commune = communes.find(
        (commune) => commune.id === externalCommune
      );
      if (commune) {
        handleCommuneSelect(commune);
        setExternalCommune(null);
      }
    }
  }, [externalCommune, GetigCommune]);

  const handleChange = (e: React.ChangeEvent) => {
    const { name, value } = e.target as HTMLInputElement;
    if (name === "express_delivery") {
      if (wilaya && whichWilayaHasPermissionToStopDesk(wilaya[0])) {
        GetDelivery(commune?.id, value, selectedOption);
      } else {
        GetDelivery(commune?.id, value, deliveryHome);
      }
      setIsExpressDelivery(value === "true");
    }
    if (name === "orderprice") {
      const prix = value === "" ? 0 : parseInt(value.replace(/\D/g, ""));
      setOrderPrice(prix ? prix : 0);
      setTotalPrice(prix ? prix : 0);
    }
  };

  const handleChangeProduct = (e: React.ChangeEvent) => {
    const { name, value } = e.target as HTMLInputElement;
    let updatesProducts = [...products];
    const tabn = name.split("_");
    const index = parseInt(tabn[1]);
    updatesProducts[index - 1].quantity = parseInt(value);
    if (parseInt(value) > 0) updatesProducts[index - 1].is_exchange = true;
    setProducts(updatesProducts);
  };

  const handleReplaceProduct = (e: React.ChangeEvent) => {
    const { name, value } = e.target as HTMLInputElement;
    let tabn = name.split("_");
    const index = parseInt(tabn[1]);
    const isExtraProduct = tabn[0].startsWith("extra");
    if (tabn[0].endsWith("productname")) {
      let theProduct = productList.find(
        (elem) => elem.logistical_description === value
      );
      if (theProduct) {
        setIsNotFound(false);
        if (isExtraProduct) {
          let updatedProducts = [...extraProducts];
          // changing productName for extra products
          updatedProducts[index - 1].product_id = theProduct.id;
          updatedProducts[index - 1].logistical_description = value;
          setExtraProducts(updatedProducts);
        } else {
          let updatedProducts = [...replaceProducts];
          updatedProducts[index - 1][0].product_id = theProduct.id;
          updatedProducts[index - 1][0].logistical_description = value;

          setReplaceProducts(updatedProducts);
        }
      }
      if (!theProduct) {
        dtStoreinfo.stock_managed ? setIsNotFound(true) : setIsNotFound(false);
        if (isExtraProduct) {
          let updatedProducts = [...extraProducts];
          updatedProducts[index - 1].logistical_description = value;
          updatedProducts[index - 1].product_id = "";
          setExtraProducts(updatedProducts);
        } else {
          let updatedProducts = [...replaceProducts];
          updatedProducts[index - 1][0].logistical_description = value;
          updatedProducts[index - 1][0].product_id = "";
          setReplaceProducts(updatedProducts);
        }
      }
    } else {
      // changing quantity
      if (isExtraProduct) {
        let updatedProducts = [...extraProducts];
        updatedProducts[index - 1].quantity = parseInt(value);
        setExtraProducts(updatedProducts);
      } else {
        let updatedProducts = [...replaceProducts];
        updatedProducts[index - 1][0].quantity = parseInt(value);
        setReplaceProducts(updatedProducts);
      }
    }
  };

  const handleCommuneSelect = (newCommune: CommuneInterface) => {
    if ((wilaya && whichWilayaHasPermissionToStopDesk(wilaya[0])) === false) {
      GetDelivery(newCommune?.id, isExpressDelivery, deliveryHome);
    }
    setCommune(newCommune);
  };

  const handleWilayaSelect = (newWilaya: wilayaInterface) => {
    if (newWilaya && (!wilaya || wilaya[0] !== newWilaya[0])) {
      if (!externalWilaya) setCommune(null);
      setWilaya(newWilaya);
      Getcommunes("?wilaya=" + newWilaya[0]);
    }
    if (!newWilaya) {
      if (!externalWilaya) setCommune(null);
      setWilaya(null);
    }
  };

  const AddFieldProduct = (index: number) => {
    Event(
      "ADD_EXCHANGE",
      "USE_EXCHANGE_WITH_OTHER_PRODUCT_BUTTON",
      "CLICK_EVENT"
    );
    let updatedReplaceProducts = [...replaceProducts];
    updatedReplaceProducts[index].push({ product_id: "", quantity: 1 });
    setReplaceProducts(updatedReplaceProducts);
  };

  const AddExtraFieldProduct = () => {
    Event("ADD_EXCHANGE", "USE_ADD_ANOTHER_PRODUCT_BUTTON", "CLICK_EVENT");
    if (dtStoreinfo.stock_managed)
      setExtraProducts([
        ...extraProducts,
        {
          product_id: "",
          quantity: 1,
        },
      ]);
    else
      setExtraProducts([
        ...extraProducts,
        {
          product_id: "",
          quantity: 1,
          exchange_reason: null,
          is_exchange: false,
          defective: false,
        },
      ]);
  };

  const RemoveFieldProduct = (index: number) => {
    Event(
      "ADD_EXCHANGE",
      "USE_REMOVE_EXCHANGE_WITH_OTHER_PRODUCT_BUTTON",
      "CLICK_EVENT"
    );
    if (replaceProducts.length > 0) {
      let updatedProducts = [...replaceProducts];
      updatedProducts[index].splice(0, 1);
      setReplaceProducts(updatedProducts);
    }
  };

  const RemoveExtraProductField = (index: number) => {
    Event("ADD_EXCHANGE", "USE_REMOVE_ADD_OTHER_PRODUCT_BUTTON", "CLICK_EVENT");
    if (extraProducts.length > 0) {
      let updatedProducts = [...extraProducts];
      updatedProducts.splice(index, 1);
      setExtraProducts(updatedProducts);
    }
  };

  const setProductDefective = (index: number) => {
    let updatedProducts = [...products];
    Event(
      "ADD_EXCHANGE",
      "TOGGLE_PRODUCT_DEFECTIVE",
      `SET_TO_${
        updatedProducts[index].defective ? "DEFECTIVE" : "NOT_DEFECTIVE"
      }`
    );
    updatedProducts[index].defective = !updatedProducts[index].defective;
    setProducts(updatedProducts);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    Event("ADD_EXCHANGE", "USE_SUBMIT_BUTTON", "CLICK_EVENT");
    let isError = false;
    let order: SubmitOrderInterface = {},
      newError: ErrorInterface = {};
    if (wilaya && typeof wilaya[0] === "number") {
      order.wilaya = wilaya[0];
    } else {
      newError.wilaya = Translate("error", "eo1");
      isError = true;
    }
    if (commune?.id) {
      order.commune = commune.id;
    } else {
      newError.commune = Translate("error", "eo2");
      isError = true;
    }
    if (address) {
      order.destination_text = address;
    } else {
      newError.address = Translate("error", "eo7");
      isError = true;
    }
    if (customerPhoneNumber) {
      order.customer_phone = customerPhoneNumber;
    } else {
      newError.phoneNumber = Translate("error", "eo5");
      isError = true;
    }
    if (customerName) {
      order.customer_name = customerName;
    } else {
      newError.name = Translate("error", "eo4");
      isError = true;
    }
    if (orderPrice >= 0) {
      order.product_price = orderPrice;
    }
    if (deliveryPrice) {
      order.price = deliveryPrice;
    }
    if (selectedOption) {
      order.delivery_type = selectedOption;
    }
    // Check of new product's quantities
    let updatedReplaceProducts = [...replaceProducts];
    replaceProducts.forEach((prod, index) => {
      if (prod[0] && prod[0].quantity <= 0) {
        updatedReplaceProducts[index][0].error = {
          errquantity: Translate("error", "e13"),
        };
        isError = true;
      }
    });
    if (isError) {
      Event(
        "ADD_EXCHANGE",
        "ERROR_INFORMATION_ENTER",
        `[${Object.keys(newError).join(",")}]`
      );
      setError(newError);
      setReplaceProducts(updatedReplaceProducts);
      return;
    }

    if (hasAddPermission && !isNotFound) {
      setIsSubmitting(true);
      const isPremium = dtStoreinfo.stock_managed;
      if (isPremium) {
        let replacementProducts = replaceProducts
          .filter((el) => el.length > 0)
          .map((el) => ({ ...el[0] }));
        // If a product is replaced with the same one, duplicate it in the request body
        products.forEach((prod, index) => {
          // If it's not replaced with a different one, add it to replacements
          if (prod.is_exchange && replaceProducts[index].length === 0)
            replacementProducts.push({ ...prod, is_exchange: false });
        });
        order.products = products
          .filter((el) => el.is_exchange)
          .concat(replacementProducts)
          .concat(extraProducts);
        // return
        let nbrExchanged =
          products.filter((prod) => prod.quantity > 0).length +
          replacementProducts.length;

        if (nbrExchanged === 0) {
          alert(Translate("error", "e12"));
          return;
        }
      } else {
        let replacementProducts = replaceProducts
          .filter((el) => el.length > 0)
          .map((el) => ({ ...el[0] }));
        // If a product is replaced with the same one, duplicate it in the request body
        products.forEach((prod, index) => {
          // If it's not replaced with a different one, add it to replacements
          if (prod.is_exchange && replaceProducts[index].length === 0)
            replacementProducts.push({ ...prod, is_exchange: false });
        });
        order.products = products
          .filter((el) => el.is_exchange)
          .concat(replacementProducts)
          .concat(extraProducts);
        let nbrExchanged =
          products.filter((prod) => prod.quantity > 0).length +
          replacementProducts.length;

        if (nbrExchanged === 0) {
          alert(Translate("error", "e12"));
          return;
        }
      }

      if (orderId) order.old_order = orderId;
      order.is_exchange = true;
      order.express = isExpressDelivery;
      order.note_to_driver = noteToDriver;
      Event("ADD_EXCHANGE", "SUBMIT_INFOS", "INFOS_VALID");

      AddExchange(order, isPremium);
    }
  };

  const source = useMemo(
    () => getParamsFromUrl("source"),
    [window.location.href]
  );
  const atLeastOneProductSelected = useMemo(
    () => products.some((prod) => prod.quantity > 0),
    [products]
  );

  if (!hasAddPermission && !source) {
    return (
      <ContainerPage page_title={Translate("orders", "addneworder")}>
        <FeatureLoked />
      </ContainerPage>
    );
  }
  if (!hasEditPermission && source) {
    return (
      <ContainerPage page_title={Translate("orders", "editorder")}>
        <FeatureLoked />
      </ContainerPage>
    );
  }
  const isLoading =
    ExchangeAdded === "1" || OrderGeting !== "2" || ProductFinded !== "2";

  return (
    <ContainerPage page_title={pageTitle}>
      {isLoading && <Loader />}
      <div className="ClSidTh StBrdTb StBgbrds widthAdd">
        {alertMessage && (
          <div className="FlWd TxtCn StRedClr">{alertMessage}</div>
        )}
        <form method="post" onSubmit={handleSubmit}>
          <ContainerPage subContainer page_title={""} small_title={true}>
            {Array.isArray(orderProducts) &&
              orderProducts.map((orderProduct, key) => {
                return (
                  <div key={key}>
                    <div className="FlWd">
                      <div
                        style={{ flexGrow: "1" }}
                        className="InFlx AlgnItm flxDrc"
                      >
                        <div
                          style={{ flexGrow: "0.02" }}
                          className="FlHg InFlx AlgnItm flxDrc"
                        >
                          <h2 className="StOpcVal"># {key + 1}</h2>
                        </div>
                        {orderProduct.picture && (
                          <div
                            style={{ flexGrow: "0.18" }}
                            className="FlHg InFlx AlgnItm"
                          ></div>
                        )}
                        <div
                          style={{ flexGrow: "0.8" }}
                          className="FlHg InFlx flxDrc"
                        >
                          <div
                            className={
                              "FlHg FlWd Stclmnf " + lang === "ar"
                                ? "StFlxStr"
                                : ""
                            }
                          >
                            <div className="InFlx flxDrc">
                              <h2 className="DlMg">
                                {" "}
                                {orderProduct.logistical_description}
                              </h2>
                            </div>
                            <div className="InFlx flxDrc">
                              <p className=" DlMg StOpcVal">
                                {Translate("exchanges", "deliveredqty")} :
                                {orderProduct.quantity}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      {
                        <div>
                          <div className="FlWd InFlx AlgnItm flxDrc">
                            <div className="Hlwd">
                              <GroupeForm
                                id={"quantity_" + (key + 1)}
                                name={"quantity_" + (key + 1)}
                                placeholder={Translate(
                                  "exchanges",
                                  "exchangequantity"
                                )}
                                text={Translate(
                                  "exchanges",
                                  "exchangequantity"
                                )}
                                type={"number"}
                                min={0}
                                max={orderProduct.quantity}
                                value={products[key].quantity}
                                defaultValue={0}
                                workfun={handleChangeProduct}
                                stclass={
                                  !atLeastOneProductSelected ||
                                  products[key].quantity > orderProduct.quantity
                                    ? "borderError"
                                    : ""
                                }
                              />
                            </div>
                            <div className="Hlwd FlHg Mrg2 RlPs">
                              <div className="FlWd FlHg InFlx AlgnItm Stclmnf">
                                <div className="FlWd InFlx flxDrc SmMarS">
                                  <p className="StSizLn DlMg">
                                    {Translate("exchanges", "productstat")}
                                  </p>
                                </div>
                                <div className="InFlx AlgnItm flxDrc">
                                  <div
                                    style={{ flexGrow: "0.2" }}
                                    className="FlHg InFlx flxDrc"
                                  >
                                    <ToggleSwitch
                                      toggleHandler={() =>
                                        setProductDefective(key)
                                      }
                                    />
                                  </div>

                                  <div
                                    style={{ flexGrow: "0.8" }}
                                    className="FlHg InFlx flxDrc"
                                  >
                                    <div
                                      className={
                                        "FlHg FlWd Stclmnf " + lang === "ar"
                                          ? "StFlxStr"
                                          : ""
                                      }
                                    >
                                      <div className="InFlx flxDrc">
                                        <b>
                                          {" "}
                                          {Translate(
                                            "exchanges",
                                            "productdefective"
                                          )}
                                        </b>
                                      </div>

                                      <p className="DlMg StOpcVal">
                                        {Translate(
                                          "exchanges",
                                          "productdefectiveseparat"
                                        )}{" "}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {replaceProducts[key].length > 0 &&
                            replaceProducts[key].map((el, pos) => (
                              <div className="FlWd InFlx Stclmnf">
                                <div className="FlWd InFlx flxDrc">
                                  <div className="Hlwd">
                                    <SelectFormSearch
                                      id={"productname_" + (key + 1)}
                                      name={"productname_" + (key + 1)}
                                      index={"logistical_description"}
                                      option={excludeProduct(
                                        productList,
                                        orderProduct
                                      )}
                                      text={Translate("product", "productname")}
                                      workfun={handleReplaceProduct}
                                      placeholder={
                                        ProductFinded === "2"
                                          ? Translate("product", "productname")
                                          : Translate("alert", "load")
                                      }
                                      value={
                                        replaceProducts[key][pos]
                                          .logistical_description
                                      }
                                      stclass={
                                        replaceProducts[key][pos].product_id ===
                                        ""
                                          ? ""
                                          : ""
                                      }
                                    />
                                  </div>
                                  <div className="Hlwd Mrg2 RlPs">
                                    <div className="Flwd InFlx flxDrc AlgnItm">
                                      <GroupeForm
                                        id={"quantity_" + (key + 1)}
                                        name={"quantity_" + (key + 1)}
                                        placeholder={Translate(
                                          "orders",
                                          "quantity"
                                        )}
                                        text={Translate("orders", "quantity")}
                                        type={"number"}
                                        value={
                                          replaceProducts[key][pos].quantity
                                        }
                                        min={1}
                                        workfun={handleReplaceProduct}
                                        style={{ width: "80%" }}
                                        stclass={
                                          isNaN(
                                            replaceProducts[key][pos].quantity
                                          ) ||
                                          replaceProducts[key][pos].quantity < 1
                                            ? "borderError"
                                            : ""
                                        }
                                      />
                                      <div
                                        className="CrsPoi InFlx JstfCnt"
                                        style={{
                                          alignItems: "flex-end",
                                          margin: "5% 0% 0% 2%",
                                        }}
                                        onClick={() => RemoveFieldProduct(key)}
                                      >
                                        {CloseSvg}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          <div className="FlWd InFlx flxDrc">
                            {/* error?.erproduct && <span>{error.erproduct}</span> */}
                            {isNotFound && (
                              <div className="FlWd StdirLn">
                                <span className="CrsPoi">
                                  {" " + Translate("product", "prdntfond")}
                                </span>
                                <Link
                                  to="/products/add"
                                  className="CrsPoi StBle"
                                >
                                  {" " + Translate("product", "addnewproduct")}
                                </Link>
                              </div>
                            )}
                            {replaceProducts[key].length === 0 &&
                              products[key].quantity > 0 && (
                                <div className="FlWd StdirLn">
                                  <span
                                    className="StBle CrsPoi"
                                    onClick={() => AddFieldProduct(key)}
                                  >
                                    <div
                                      className={
                                        "StMrtg2 StHgVls InFlx AlgnItm StBlFil StSmallSvg"
                                      }
                                    >
                                      <p>
                                        {" " +
                                          Translate(
                                            "exchanges",
                                            "exchangewithanother"
                                          )}
                                      </p>{" "}
                                      {ExchangeSvg}
                                    </div>
                                  </span>
                                </div>
                              )}
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                );
              })}
          </ContainerPage>
          <ContainerPage subContainer>
            {extraProducts.length === 0 && (
              <div className="FlWd StdirLn">
                <span className="StBle CrsPoi" onClick={AddExtraFieldProduct}>
                  <div
                    className={
                      "StMrtg2 StHgVls InFlx AlgnItm StBlFil StSmallSvg"
                    }
                  >
                    <p>{" " + Translate("exchanges", "sellanotherproduct")}</p>{" "}
                    {ExchangeSvg}
                  </div>
                </span>
              </div>
            )}
            <div>
              {extraProducts.length > 0 && (
                <div className="FlWd InFlx flxDrc">
                  {" "}
                  <h4>{Translate("exchanges", "newproducts")}</h4>
                </div>
              )}
              {extraProducts.length > 0 &&
                extraProducts.map((el, idx) => (
                  <div className="FlWd InFlx Stclmnf">
                    <div className="FlWd InFlx flxDrc">
                      <div className="Hlwd">
                        <SelectFormSearch
                          id={"extraproductname_" + (idx + 1)}
                          name={"extraproductname_" + (idx + 1)}
                          index={"logistical_description"}
                          option={productList}
                          text={Translate("product", "productname")}
                          workfun={handleReplaceProduct}
                          placeholder={
                            ProductFinded === "2"
                              ? Translate("product", "productname")
                              : Translate("alert", "load")
                          }
                          value={extraProducts[idx].logistical_description}
                          stclass={
                            extraProducts[idx].product_id === "" ? "" : ""
                          }
                        />
                      </div>
                      <div className="Hlwd Mrg2 RlPs">
                        <div className="Flwd InFlx flxDrc AlgnItm">
                          <GroupeForm
                            id={"extraquantity_" + (idx + 1)}
                            name={"extraquantity_" + (idx + 1)}
                            placeholder={Translate("orders", "quantity")}
                            text={Translate("orders", "quantity")}
                            type={"number"}
                            value={extraProducts[idx].quantity}
                            min={1}
                            workfun={handleReplaceProduct}
                            style={{ width: "80%" }}
                            stclass={
                              isNaN(extraProducts[idx].quantity) ||
                              extraProducts[idx].quantity < 1
                                ? "borderError"
                                : ""
                            }
                          />
                          <div
                            className="CrsPoi InFlx JstfCnt"
                            style={{
                              alignItems: "flex-end",
                              margin: "5% 0% 0% 2%",
                            }}
                            onClick={() => RemoveExtraProductField(idx)}
                          >
                            {CloseSvg}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="FlWd InFlx flxDrc">
                      {/* error?.erproduct && <span>{error.erproduct}</span> */}
                      {isNotFound && (
                        <div className="FlWd StdirLn">
                          <span className="CrsPoi">
                            {" " + Translate("product", "prdntfond")}
                          </span>
                          <Link to="/products/add" className="CrsPoi StBle">
                            {" " + Translate("product", "addnewproduct")}
                          </Link>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
            </div>
          </ContainerPage>
          <ContainerPage
            subContainer
            page_title={Translate("orders", "clientinfo")}
            small_title={true}
          >
            <div className="FlWd InFlx flxDrc">
              <div className="Hlwd">
                <GroupeForm
                  id={"customerName"}
                  name={"customerName"}
                  placeholder={Translate("orders", "customername")}
                  text={Translate("orders", "customername")}
                  type={"text"}
                  value={customerName}
                  workfun={(e: React.ChangeEvent) => {
                    const { value } = e.target as HTMLInputElement;
                    setCustomerName(value);
                  }}
                  error={error?.name}
                  stclass={!customerName ? "borderError" : ""}
                />
              </div>
              <div className="Hlwd Mrg2">
                <GroupeForm
                  id={"customerphonenumber"}
                  name={"customerphonenumber"}
                  placeholder={Translate("orders", "customerphonenumber")}
                  text={Translate("orders", "customerphonenumber")}
                  type={"tel"}
                  pattern="^(((\+216)?[1-9]\d{7})|((0?|(00|\+)?213)[56789]\d{8}))$"
                  value={customerPhoneNumber}
                  workfun={(e: React.ChangeEvent) => {
                    const { value } = e.target as HTMLInputElement;
                    setCustomerPhoneNumber(value);
                  }}
                  error={error?.phoneNumber}
                  stclass={
                    !checkPhoneNumberValidity(customerPhoneNumber!)
                      ? "borderError"
                      : ""
                  }
                />
              </div>
            </div>
          </ContainerPage>
          <ContainerPage
            subContainer
            withOvrl={true}
            page_title={Translate("orders", "deliverydetails")}
            small_title={true}
          >
            <div className="FlWd InFlx flxDrc" style={{ height: "100%" }}>
              <div className="Hlwd">
                <GroupeForm
                  id={"adresse"}
                  name={"adresse"}
                  placeholder={Translate("orders", "adresse")}
                  text={Translate("orders", "adresse")}
                  type={"text"}
                  value={address}
                  workfun={(e: React.ChangeEvent) => {
                    const { value } = e.target as HTMLInputElement;
                    setAddress(value);
                  }}
                  error={error?.address}
                  stclass={!address ? "borderError" : ""}
                />
                <div
                  className="FlWd InFlx flxDrc"
                  style={{ margin: "28px 0 28px 0" }}
                >
                  <div className="Hlwd">
                    <Select
                      label={Translate("orders", "wilaya")}
                      placholder={Translate("orders", "citySearch")}
                      search={true}
                      Options={dataWilaya}
                      fieldShow={1}
                      name="wilaya"
                      loading={GetingWilaya === "0"}
                      value={wilaya}
                      onChange={handleWilayaSelect}
                      maxSize="200px"
                      containerClass={!wilaya ? "borderError" : ""}
                    />
                  </div>
                  <div className="Hlwd Mrg2">
                    <Select
                      label={Translate("orders", "district")}
                      placholder={Translate("orders", "districtSearch")}
                      search={true}
                      Options={DataCommune}
                      fieldShow={"name"}
                      name="commune"
                      loading={GetigCommune === "0"}
                      value={commune}
                      onChange={handleCommuneSelect}
                      maxSize="200px"
                      containerClass={!commune ? "borderError" : ""}
                    />
                  </div>
                </div>
                <GroupeForm
                  id={"orderprice"}
                  name={"orderprice"}
                  placeholder={Translate("exchanges", "clientamount")}
                  text={Translate("exchanges", "clientamount")}
                  type={"text"}
                  value={orderPrice}
                  workfun={handleChange}
                  // error={error?.erprice}
                  stclass={typeof orderPrice !== "number" ? "borderError" : ""}
                />
              </div>
              <div className="Hlwd Mrg2">
                <GroupeForm
                  id={"notetodriver"}
                  name={"notetodriver"}
                  placeholder={Translate("orders", "notetodriver")}
                  text={Translate("orders", "notetodriver")}
                  type={"text"}
                  value={noteToDriver}
                  workfun={(e: React.ChangeEvent) => {
                    const { value } = e.target as HTMLInputElement;
                    setNoteToDriver(value);
                  }}
                  textarea={true}
                />
                {wilaya === null ? (
                  ""
                ) : wilaya && whichWilayaHasPermissionToStopDesk(wilaya[0]) ? (
                  <div className="spctar Strw InFlx">
                    <div className="radio">
                      <label className="AlgnItm Strw InFlx">
                        <input
                          style={{ width: "auto" }}
                          type="radio"
                          value={1}
                          checked={selectedOption == 1}
                          onChange={(e: React.ChangeEvent) => {
                            const { value } = e.target as HTMLInputElement;

                            setSelectedOption(value);
                          }}
                        />
                        <span>{Translate("orders", "home")}</span>
                      </label>
                    </div>
                    <div className="radio">
                      <label className="AlgnItm  Strw InFlx">
                        <input
                          style={{ width: "auto" }}
                          type="radio"
                          value={2}
                          checked={selectedOption == 2}
                          onChange={(e: React.ChangeEvent) => {
                            const { value } = e.target as HTMLInputElement;

                            setSelectedOption(value);
                          }}
                        />
                        <span>{Translate("orders", "stpdsk")}</span>
                      </label>
                    </div>
                  </div>
                ) : (
                  <p className="StRedClr">
                    {Translate("orders", "nostopdesk")}
                  </p>
                )}
                {wilaya && wilaya[0] === 16 ? (
                  <div className="SmMarS2">
                    <SelectForm
                      id={"expressdelivery"}
                      name={"express_delivery"}
                      option={[
                        { text: Translate("orders", "no"), val: false },
                        { text: Translate("orders", "yes"), val: true },
                      ]}
                      value={isExpressDelivery}
                      text={Translate("orders", "expressdelivery")}
                      workfun={handleChange}
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </ContainerPage>
          <span
            className="DsBlc FlWd"
            style={{
              height: "1px",
              backgroundColor: "var(--fntClr)",
              opacity: ".2",
            }}
          ></span>
          <div className="FlWd TxtCn StRedClr">
            {commune &&
              !commune.id &&
              commune.name &&
              Translate("error", "Order out of our service")}
          </div>
          <div className="StMarMx stbold">
            {/* <div className="PsinLan SmMarS">
              <span>{Translate("orders", "orderprice") + " :"}</span>
              <span className="Mrg2">{this.state.orderprice + "  Da"}</span>
            </div> */}
            <div className="PsinLan SmMarS">
              <span>{Translate("orders", "deliveryprice")}</span>
              <span> ({Translate("exchanges", "extracharge")}) </span>
              <span>:</span>
              <span className="Mrg2">
                {lang == "ar"
                  ? deliveryPrice + "   " + dtStoreinfo.country.ar_currency
                  : deliveryPrice + "   " + dtStoreinfo.country.lt_currency}
              </span>
            </div>
            <div className="PsinLan SmMarS">
              <span>{Translate("exchanges", "clientamount") + " :"}</span>
              <span className="Mrg2">
                {lang == "ar"
                  ? totalPrice + "   " + dtStoreinfo.country.ar_currency
                  : totalPrice + "   " + dtStoreinfo.country.lt_currency}
              </span>
            </div>
          </div>

          <div className="MrAot StMarMx responseDiv" style={{ width: "25%" }}>
            <Button BtnText={buttonTitle} />
          </div>
        </form>
      </div>
    </ContainerPage>
  );
};

function mapState(state: any) {
  const {
    GetingWilaya,
    dataWilaya,
    GetigCommune,
    DataCommune,
    GetingDelivery,
    dataDelivery,
    dataCountry,
    GetingPays,
  } = state.common;
  const { ProductFinded, data } = state.product;
  const { OrderGeting, OrdersList } = state.orders;
  const { ExchangeAdded, dataAddExchange } = state.exchanges;

  const { DataSearch, Searching } = state.search;
  const { dtStoreinfo, dataProfile } = state.user;
  return {
    GetingWilaya,
    dataWilaya,
    GetigCommune,
    DataCommune,
    data,
    ProductFinded,
    ExchangeAdded,
    dataAddExchange,
    GetingDelivery,
    dataDelivery,
    OrderGeting,
    OrdersList,
    DataSearch,
    Searching,
    dtStoreinfo,
    dataProfile,
    dataCountry,
    GetingPays,
  };
}

const actionCreators = {
  Getwilayas: commonActions.Getwilayas,
  Getcommunes: commonActions.Getcommunes,
  GetAllProduct: productActions.GetAllProduct,
  GetDelivery: commonActions.GetDelivery,
  AddExchange: exchangeActions.AddExchange,
  GetOrders: ordersActions.GetOrders,
  UpdateUnfOrder: ordersActions.UpdateUnfOrder,
  SendAlert: alertActions.SendAlert,
  ClearStore: commonActions.ClearStore,
  GetCountry: commonActions.GetCountry,
};

const ConnectedExchangePage = connect(mapState, actionCreators)(ExchangePage);

export { ConnectedExchangePage as ExchangePage };
