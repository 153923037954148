import React, { Component } from "react";
import { connect } from "react-redux";
import ContainerPage from "../_Common/ContainerPage";
import ElmentStatus from "./ElmentStatus";
import {
  ShipedSvg,
  WaitingSvg,
  PendingSvg,
  DelivredSvg,
  CancledSvg,
  PostpondSvg,
  AcceptedSvg,
  InHubSvg,
  TransitSvg,
  UnconfirmedSvg,
  RefreshSvg,
  ViewSvg,
  PackageSvg,
  WarningSvg,
  OutOfStockSvg,
  ToReturnSvg,
  TakenByStoreSvg,
  delivredOrdsSvg,
} from "../_Common/IconSvg";
import { Translate, FormatCurrency } from "../../../utils/lang/translate";
import GroupeBtnSp from "../_Common/GroupeBtnSp";
import GroupeBtn from "../_Common/GroupeBtn";
import StatLoader from "./StatLoader";
import RateLoader from "./RateLoader";
import DatePicker from "../../_Common/DatePicker";
import LoaderBln from "../_Common/LoaderBln";
import Table from "../_Common/Table";
import TableLoader from "../_Common/TableLoader";
import {
  dashboardActions,
  paymentActions,
  ordersActions,
  productActions,
} from "../../../actions";
import ActionsList from "../_Common/ActionsList";
import StatsElement from "../_Common/StatsElement";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { addUrlProps, UrlQueryParamTypes } from "react-url-query";
import { initGAPg, Event } from "../_Common";
import {
  OLD_PREMIUM_STATUSES_LIST,
  PREMIUM_STATUSES_LIST,
  STARTER_STATUSES_LIST,
} from "../../constants";
import ReactTooltip from "react-tooltip";

const urlPropsQueryConfig = {
  began: { type: UrlQueryParamTypes.string },
  end: { type: UrlQueryParamTypes.string },
};

const date_difference = (date1, date2) => {
  var dateFuture = new Date(date1);
  var pastDate = new Date(date2);

  var seconds = Math.floor((dateFuture - pastDate) / 1000);
  var minutes = Math.floor(seconds / 60);
  var hours = Math.floor(minutes / 60);
  var days = Math.floor(hours / 24);

  hours = hours - days * 24;
  minutes = minutes - days * 24 * 60 - hours * 60;
  return { days, hours, minutes };
};

const is_exclusive_test_store = (store) =>
  store.use_new_status && store.use_new_status.toString() === "true";

class DashboardPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      svgDt: {
        s4: WaitingSvg,
        s5: WaitingSvg,
        s6: WaitingSvg,
        s7: AcceptedSvg,
        s8: InHubSvg,
        sp8: PendingSvg,
        s9: TransitSvg,
        s11: PendingSvg,
        s12: OutOfStockSvg,
        s15: PendingSvg,
        s22: AcceptedSvg,
        s31: ShipedSvg,
        s32: ShipedSvg,
        s41: DelivredSvg,
        s50: CancledSvg,
        s42: PostpondSvg,
        s51: ToReturnSvg,
        s52: TakenByStoreSvg,
        s1: delivredOrdsSvg,
      },
      statusList:
        this.props.gtStoreInf === "1" && this.props.dtStoreinfo
          ? this.props.dtStoreinfo.stock_managed
            ? is_exclusive_test_store(this.props.dtStoreinfo)
              ? PREMIUM_STATUSES_LIST
              : OLD_PREMIUM_STATUSES_LIST
            : STARTER_STATUSES_LIST
          : [],
      orders:
        this.props.OrderGeting === "2"
          ? this.props.OrdersList.ressuc.list.results
          : [],
      gettingGlobalStatistics: false, // 2 fetch are used with same action, one for period related statistics, the other with no date
      globalStats: null,
      grid: true,
      windowWidth: 0,
    };
    this.handleResize = this.handleResize.bind(this);
    // this.gettingGlobalStatistics=false; // 2 fetch are used with same action, one for period related statistics, the other with no date
    this.crntfilter = "";
    this.showdata = false;
    this.dateRef = React.createRef();
    this.actionDec = false;
  }
  static propTypes = {
    began: PropTypes.string,
    end: PropTypes.string,
    /********************** */
    onChangeBegan: PropTypes.func,
    onChangeEnd: PropTypes.func,
  };
  componentDidMount() {
    document.title = "Maystro Delivery - " + Translate("titles", "dashboard");
    initGAPg();
    this.exctuteFilterUrl(this.getFilterToState(this.props));
    this.props.GetGlobalStats("");
    this.props.GetTodayStats();
    this.props.GetCurrentorders();
    // Fetch financial stats
    this.props.ViewPayment();
    this.props.GetOrders("");
    this.props.GetUnfOrders("?status=0");
    this.props.GetTopProduct("");
    this.props.GetAllProduct();
    this.props.ViewStockProduct();
    this.props.GetUnfOrdersCount();
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  }
  componentWillReceiveProps(nextProps) {
    const {
      began,
      end,
      GetingGlobalStat,
      GlobalStats,
      OrderGeting,
      OrdersList,
      gtStoreInf,
      dtStoreinfo,
    } = nextProps;

    if (
      this.props.gtStoreInf === "0" &&
      gtStoreInf === "1" &&
      dtStoreinfo &&
      this.state.statusList.length === 0
    ) {
      this.setState({
        statusList: dtStoreinfo.stock_managed
          ? is_exclusive_test_store(this.props.dtStoreinfo)
            ? PREMIUM_STATUSES_LIST
            : OLD_PREMIUM_STATUSES_LIST
          : STARTER_STATUSES_LIST,
      });
    }
    if (began && end) {
      const date1 = new Date(began);
      const date2 = new Date(end);
      this.showdata =
        Math.ceil(Math.abs(date2 - date1) / (1000 * 60 * 60 * 24)) >= 3 ||
        began === end;
      this.showdata && this.exctuteFilterUrl(this.getFilterToState(nextProps));
    }
    if (this.props.GetingGlobalStat === "0" && GetingGlobalStat === "1") {
      this.setState({
        ...this.state,
        globalStats: GlobalStats,
        gettingGlobalStatistics: false,
      });
    }
    if (
      this.props.OrderGeting === "1" &&
      OrderGeting === "2" &&
      OrdersList &&
      OrdersList.ressuc &&
      OrdersList.ressuc.list
    ) {
      this.setState({
        orders: OrdersList.ressuc.list.results,
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize() {
    this.setState({
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
    });
  }
  getFilterToState(props) {
    let Filter = props ? props.location.search : this.props.location.search;
    return Filter;
  }
  exctuteFilterUrl(nextFilter, force, globalStats) {
    if (globalStats) {
      this.props.GetStatus(nextFilter, globalStats);
      return;
    }
    let prevFilter = this.crntfilter;
    if (prevFilter !== nextFilter || force) {
      this.crntfilter = nextFilter;
      if (
        this.props.began &&
        this.props.end &&
        this.props.began === this.props.end
      )
        this.props.GetStatus(
          nextFilter.replace(/began=.*&end=.*/, "date=" + this.props.began)
        );
      else this.props.GetStatus(nextFilter);
      // this.props.GetTopProduct(nextFilter);
    }
  }
  CallPropsS() {
    this.CallProps = true;
  }
  SendDateFilter() {
    this.exctuteFilterUrl(this.getFilterToState(this.props));
  }
  RefreshDahboard() {
    Event("Dashboard", "USE_REFRESH_BUTTON", "CLICK_EVENT");
    this.exctuteFilterUrl(this.getFilterToState(this.props), true);
  }
  getStatusAction(status) {
    switch (status) {
      case 7:
        return "was collected by agent";
      case 15:
        return "is ready to ship";
      case 22:
        return "was assigned for delivery";
      case 31:
        return "was shipped";
      case 41:
        return "was delivered";
      case 50:
        return "was aborted";
      default:
        return undefined;
    }
  }
  render() {
    const {
      dtStoreinfo,
      dataProfile,
      OrderGeting,
      GetinunfOrd,
      dataInfOrd,
      DataStock,
      data,
      GetingStat,
      Statdata,
      GetingGlobalStat,
      GlobalStats,
      GettingTodayStats,
      TodayStats,
      GetingTopProd,
      TopProdData,
      ViewPay,
      ViewPaydata,
      GetinunfOrdCount,
      dataInfOrdCount,
    } = this.props;

    const statusListPrincipale = this.state.statusList.slice(0, 7);
    const statusListAdded = this.state.statusList.slice(7);

    let FIlterMon = (
      <div className="InFlx flxDrc">
        <GroupeBtnSp
          style={{ margin: "0", zoom: "1.2" }}
          data_btn={[
            {
              type: "BDgInpc",
              action: this.RefreshDahboard.bind(this),
              svgbtn: RefreshSvg,
            },
          ]}
        />
        <DatePicker
          from={this.props.began}
          initDate={true}
          to={this.props.end}
          changeFrom={this.props.onChangeBegan}
          changeTo={this.props.onChangeEnd}
          changeDate={this.props.onChangeDate}
        />
      </div>
    );

    let bodytab = [];
    if (
      GetingTopProd === "1" &&
      TopProdData &&
      Array.isArray(TopProdData.top_products)
    ) {
      TopProdData.top_products.forEach((elem, key) => {
        bodytab.push([
          elem.product__logistical_description,
          elem.quantity,
          elem.pourcentage + "%",
        ]);
      });
    }
    // Actions items
    let actions = [],
      nbrUnfullfilled = 0;

    if (GetinunfOrdCount === "2") {
      nbrUnfullfilled = dataInfOrdCount?.temp_orders_count;
      actions.push({
        title: `${
          nbrUnfullfilled > 0 ? nbrUnfullfilled : Translate("orders", "nothing")
        } ${Translate("dashboard", "unfullfilledorders")}`,
        icon: UnconfirmedSvg,
        to: "/unfulfilled/order",
      });
    }
    if (dtStoreinfo.stock_managed) {
      if (DataStock && DataStock.list && data && data.list) {
        let productsDict = {};
        DataStock.list.forEach((row) => {
          if (!productsDict.hasOwnProperty(row.product__id))
            productsDict[row.product__id] = 0;

          productsDict[row.product__id] += row.quantity;
        });
        let lowStockProducts = data.list.filter(
          (prd) => productsDict[prd.id] <= prd.low_stock_level
        );
        if (lowStockProducts.length)
          actions.push({
            title: `${lowStockProducts.length} ${Translate(
              "dashboard",
              "lowstockproducts"
            )}`,
            icon: PackageSvg,
            to: "/stock/products",
            style: { backgroundColor: "rgba(222,174,61,0.4)" },
          });
      }

      // Pending orders (deprecated status),to be removed in future
      if (GetingGlobalStat === "1" && GlobalStats) {
        let pending = GlobalStats.stat.find((el) => el.status === 11);
        if (pending && pending.total > 0) {
          actions.push({
            title: `${pending.total} ${Translate("dashboard", "orders11")}`,
            icon: PendingSvg,
            to: "/orders?status=11",
            // style: {backgroundColor: "rgba(244,67,54,0.4)"}
          });
        }
      }
    } else if (
      !dtStoreinfo.stock_managed &&
      GetingGlobalStat === "1" &&
      GlobalStats
    ) {
      let inpreparation = GlobalStats.stat.find((el) => el.status === 4);
      let needsInterv = GlobalStats.stat.find((el) => el.status === 32);
      let READY_TO_RETURN = GlobalStats.stat.find((el) => el.status === 51);
      let TAKEN_BY_STORE = GlobalStats.stat.find((el) => el.status === 52);
      actions.push({
        title: `${inpreparation ? inpreparation.total : "0"} ${Translate(
          "dashboard",
          "missingpickup"
        )}`,
        icon: PackageSvg,
        to: "/orders?status=4",
      });
      if (needsInterv && needsInterv.total)
        actions.push({
          title: `${needsInterv.total} ${Translate("dashboard", "orders32")}`,
          icon: WarningSvg,
          to: "/orders?status=32",
          style: { backgroundColor: "rgba(244,67,54,0.4)" },
        });
      if (READY_TO_RETURN && READY_TO_RETURN.total)
        actions.push({
          title: `${READY_TO_RETURN.total} ${Translate(
            "dashboard",
            "orders51"
          )}`,
          icon: WarningSvg,
          to: "/orders?status=51",
          style: { backgroundColor: "rgba(244,67,54,0.4)" },
        });
      if (TAKEN_BY_STORE && TAKEN_BY_STORE.total)
        actions.push({
          title: `${TAKEN_BY_STORE.total} ${Translate(
            "dashboard",
            "orders52"
          )}`,
          icon: WarningSvg,
          to: "/orders?status=52",
          style: { backgroundColor: "rgba(244,67,54,0.4)" },
        });
    }
    // Orders log
    let logs = [];
    if (OrderGeting === "2") {
      let releventStatus = [7, 15, 22, 31, 41, 50];
      logs = this.state.orders
        .filter((el) => releventStatus.indexOf(el.status) > -1)
        .sort((a, b) => new Date(a.ordered_at) - new Date(b.ordered_at))
        .reverse()
        .map((el) => ({
          order_id: el.display_id,
          status: el.status,
          date: el.ordered_at,
        }));
    }
    return (
      <ContainerPage
        page_title={Translate("titles", "dashboard")}
        // data_top={FIlterMon}
      >
        <div className="FlWd StDrc spctwB">
          <div
            className="col"
            style={{ width: "75% !important", flexGrow: 0.99 }}
          >
            {GetingGlobalStat === "0" ? (
              <div className="Inlflx FlWd StDrc spctwB">
                <StatLoader />
                <StatLoader />
                <StatLoader /> <StatLoader />
                <StatLoader />
                <StatLoader />
              </div>
            ) : !dtStoreinfo.stock_managed ? (
              this.state.windowWidth >= 789 ? (
                <div className="boxGrid">
                  {GetingGlobalStat === "1" &&
                    GlobalStats &&
                    statusListPrincipale.map((stat, key) => {
                      let elem = GlobalStats.stat.find(
                        (el) => el.status === stat
                      ) || { status: stat, total: 0 };

                      let status = (elem.status + "").replace("p", "");

                      return (
                        <div>
                          <Link
                            key={key}
                            onClick={() =>
                              Event(
                                "Dashboard",
                                "Click in STATUS_" +
                                  Translate("dashboard", "orders" + status),
                                "STATUS_" + status
                              )
                            }
                            style={{
                              width: "22%",
                              height: "89px",
                              minWidth: "90px",
                              marginBottom: "2%",
                            }}
                            className="SpcMrgvl StatCard"
                            to={
                              "/orders" +
                              // this.props.location.search +
                              "?status=" +
                              status
                            }
                            data-tip
                            data-for={`card-status-${elem.status}-tooltip-${key}`}
                          >
                            <ElmentStatus
                              dataText={Translate(
                                "dashboard",
                                "orders" + elem.status
                              )}
                              dataNumber={elem.total}
                              SvgStatus={this.state.svgDt["s" + elem.status]}
                              status={elem.status}
                              grid={
                                status == 1 || status == 51 || status == 52
                                  ? false
                                  : true
                              }
                            />
                            {elem.status === 32 && elem.total > 0 && (
                              <ReactTooltip
                                id={`card-status-${elem.status}-tooltip-${key}`}
                              >
                                <p style={{ maxWidth: "240px" }}>
                                  {Translate("tooltips", "needsintervention")}
                                </p>
                              </ReactTooltip>
                            )}
                          </Link>
                        </div>
                      );
                    })}
                  <div className="drctbox Inlflx FlWd StDrc spctwB ">
                    {GetingGlobalStat === "1" &&
                      GlobalStats &&
                      statusListAdded.map((stat, key) => {
                        let elem = GlobalStats.stat.find(
                          (el) => el.status === stat
                        ) || { status: stat, total: 0 };

                        let status = (elem.status + "").replace("p", "");

                        return (
                          <div style={{ minWidth: "29%" }}>
                            <Link
                              key={key}
                              onClick={() =>
                                Event(
                                  "Dashboard",
                                  "Click in STATUS_" +
                                    Translate("dashboard", "orders" + status),
                                  "STATUS_" + status
                                )
                              }
                              style={{
                                width: "22%",
                                height: "89px",
                                minWidth: "90px",
                                marginBottom: "2%",
                              }}
                              className="SpcMrgvl StatCard"
                              to={
                                "/orders" +
                                // this.props.location.search +
                                "?status=" +
                                status
                              }
                              data-tip
                              data-for={`card-status-${elem.status}-tooltip-${key}`}
                            >
                              <ElmentStatus
                                dataText={Translate(
                                  "dashboard",
                                  "orders" + elem.status
                                )}
                                dataNumber={elem.total}
                                SvgStatus={this.state.svgDt["s" + elem.status]}
                                status={elem.status}
                                grid={
                                  status == 1 || status == 51 || status == 52
                                    ? false
                                    : true
                                }
                              />
                              {elem.status === 32 && elem.total > 0 && (
                                <ReactTooltip
                                  id={`card-status-${elem.status}-tooltip-${key}`}
                                >
                                  <p style={{ maxWidth: "240px" }}>
                                    {Translate("tooltips", "needsintervention")}
                                  </p>
                                </ReactTooltip>
                              )}
                            </Link>
                          </div>
                        );
                      })}

                    {GettingTodayStats === "1" &&
                      TodayStats &&
                      [41].map((stat, key) => {
                        let elem = TodayStats.stat.find(
                          (el) => el.status === stat
                        ) || { status: stat, total: 0 };
                        let status = elem.status + "";
                        return (
                          <div style={{ minWidth: "29%" }}>
                            <Link
                              key={key}
                              onClick={() =>
                                Event(
                                  "Dashboard",
                                  "Click in STATUS_" +
                                    Translate("dashboard", "orders" + status),
                                  "STATUS_" + status
                                )
                              }
                              style={{
                                width: "22%",
                                minWidth: "120px",
                                marginBottom: "2%",
                              }}
                              className="SpcMrgvl StatCard"
                              to={"/orders" + "?status=" + status}
                            >
                              <ElmentStatus
                                dataText={`${Translate(
                                  "dashboard",
                                  "orders" + elem.status
                                )} ${Translate("dashboard", "today")}`}
                                dataNumber={elem.total}
                                SvgStatus={this.state.svgDt["s" + elem.status]}
                                status={elem.status}
                                grid={this.state.grid}
                              />
                            </Link>
                          </div>
                        );
                      })}
                  </div>
                </div>
              ) : (
                <div className="boxGrid">
                  {GetingGlobalStat === "1" &&
                    GlobalStats &&
                    this.state.statusList.map((stat, key) => {
                      let elem = GlobalStats.stat.find(
                        (el) => el.status === stat
                      ) || { status: stat, total: 0 };

                      let status = (elem.status + "").replace("p", "");

                      return (
                        <div>
                          <Link
                            key={key}
                            onClick={() =>
                              Event(
                                "Dashboard",
                                "Click in STATUS_" +
                                  Translate("dashboard", "orders" + status),
                                "STATUS_" + status
                              )
                            }
                            style={{
                              width: "22%",
                              height: "89px",
                              minWidth: "90px",
                              marginBottom: "2%",
                            }}
                            className="SpcMrgvl StatCard"
                            to={
                              "/orders" +
                              // this.props.location.search +
                              "?status=" +
                              status
                            }
                            data-tip
                            data-for={`card-status-${elem.status}-tooltip-${key}`}
                          >
                            <ElmentStatus
                              dataText={Translate(
                                "dashboard",
                                "orders" + elem.status
                              )}
                              dataNumber={elem.total}
                              SvgStatus={this.state.svgDt["s" + elem.status]}
                              status={elem.status}
                              grid={
                                status == 1 || status == 51 || status == 52
                                  ? false
                                  : true
                              }
                            />
                            {elem.status === 32 && elem.total > 0 && (
                              <ReactTooltip
                                id={`card-status-${elem.status}-tooltip-${key}`}
                              >
                                <p style={{ maxWidth: "240px" }}>
                                  {Translate("tooltips", "needsintervention")}
                                </p>
                              </ReactTooltip>
                            )}
                          </Link>
                        </div>
                      );
                    })}
                  {GettingTodayStats === "1" &&
                    TodayStats &&
                    [41].map((stat, key) => {
                      let elem = TodayStats.stat.find(
                        (el) => el.status === stat
                      ) || { status: stat, total: 0 };
                      let status = elem.status + "";
                      return (
                        <div style={{ minWidth: "29%" }}>
                          <Link
                            key={key}
                            onClick={() =>
                              Event(
                                "Dashboard",
                                "Click in STATUS_" +
                                  Translate("dashboard", "orders" + status),
                                "STATUS_" + status
                              )
                            }
                            style={{
                              width: "22%",
                              minWidth: "120px",
                              marginBottom: "2%",
                            }}
                            className="SpcMrgvl StatCard"
                            to={"/orders" + "?status=" + status}
                          >
                            <ElmentStatus
                              dataText={`${Translate(
                                "dashboard",
                                "orders" + elem.status
                              )} ${Translate("dashboard", "today")}`}
                              dataNumber={elem.total}
                              SvgStatus={this.state.svgDt["s" + elem.status]}
                              status={elem.status}
                              grid={this.state.grid}
                            />
                          </Link>
                        </div>
                      );
                    })}
                </div>
              )
            ) : (
              <div className="Inlflx FlWd StDrc spcEvn StFlxWrp">
                {GetingGlobalStat === "1" &&
                  GlobalStats &&
                  this.state.statusList.map((stat, key) => {
                    let elem = GlobalStats.stat.find(
                      (el) => el.status === stat
                    ) || { status: stat, total: 0 };
                    let status = (elem.status + "").replace("p", "");
                    return (
                      <Link
                        key={key}
                        onClick={() =>
                          Event(
                            "Dashboard",
                            "Click in STATUS_" +
                              Translate("dashboard", "orders" + status),
                            "STATUS_" + status
                          )
                        }
                        style={{
                          width: "22%",
                          minWidth: "90px",
                          marginBottom: "2%",
                        }}
                        className="SpcMrgvl StatCard"
                        to={
                          "/orders" +
                          // this.props.location.search +
                          "?status=" +
                          status
                        }
                        data-tip
                        data-for={`card-status-${elem.status}-tooltip-${key}`}
                      >
                        <ElmentStatus
                          dataText={Translate(
                            "dashboard",
                            "orders" + elem.status
                          )}
                          dataNumber={elem.total}
                          SvgStatus={this.state.svgDt["s" + elem.status]}
                          status={elem.status}
                          grid
                        />
                        {elem.status === 32 && elem.total > 0 && (
                          <ReactTooltip
                            id={`card-status-${elem.status}-tooltip-${key}`}
                          >
                            <p style={{ maxWidth: "240px" }}>
                              {Translate("tooltips", "needsintervention")}
                            </p>
                          </ReactTooltip>
                        )}
                      </Link>
                    );
                  })}
                {GettingTodayStats === "1" &&
                  TodayStats &&
                  [41].map((stat, key) => {
                    let elem = TodayStats.stat.find(
                      (el) => el.status === stat
                    ) || { status: stat, total: 0 };
                    let status = elem.status + "";
                    return (
                      <Link
                        key={key}
                        onClick={() =>
                          Event(
                            "Dashboard",
                            "Click in STATUS_" +
                              Translate("dashboard", "orders" + status),
                            "STATUS_" + status
                          )
                        }
                        style={{
                          width: "22%",
                          minWidth: "120px",
                          marginBottom: "2%",
                        }}
                        className="SpcMrgvl StatCard"
                        to={"/orders" + "?status=" + status}
                      >
                        <ElmentStatus
                          dataText={`${Translate(
                            "dashboard",
                            "orders" + elem.status
                          )} ${Translate("dashboard", "today")}`}
                          dataNumber={elem.total}
                          SvgStatus={this.state.svgDt["s" + elem.status]}
                          status={elem.status}
                          grid
                        />
                      </Link>
                    );
                  })}
              </div>
            )}
            {GettingTodayStats === "0" ? (
              <div className="Inlflx FlWd StDrc spcEvn">
                <StatLoader />
                <StatLoader />
              </div>
            ) : (
              <div className="Inlflx FlWd StDrc spcEvn StFlxWrp"></div>
            )}
            {/* Orders related actions list */}
            <div className="FlWd ">
              <ActionsList items={actions} />
            </div>
            {/* Performance  and finance tabs */}
            <div
              className="FlWd flex-grid responsFlx StMrtp2"
              style={{ marginTop: "12px" }}
            >
              <div className="col-two-three" id="performance-tab">
                <div className="FlHg StBrdTb StBgbrds ClSidTh SthgMaxC">
                  <div className="RlPs" style={{ margin: "7px 20px" }}>
                    <div className="FlWd InFlx responsFlx spctwB AlgnItm">
                      <h3 className="StdirLn" style={{ margin: 0 }}>
                        {Translate("dashboard", "performance")}
                      </h3>
                      {FIlterMon}
                    </div>

                    {GetingStat === "0" ? (
                      <RateLoader />
                    ) : (
                      GetingStat === "1" &&
                      Statdata &&
                      Statdata && (
                        <div className="FlWd">
                          <div className="FlWd InFlx responsFlx">
                            <div className="col-half">
                              <StatsElement
                                title={Translate("dashboard", "deliveryrate")}
                                value={
                                  this.showdata
                                    ? Math.floor(
                                        Statdata.delivery_rate
                                      ).toFixed(2) + "%"
                                    : "_"
                                }
                                subcontent={
                                  this.showdata ? (
                                    <span>
                                      <span
                                        style={{
                                          color:
                                            Math.floor(
                                              Statdata.diff_delivery_rate
                                            ) > 0
                                              ? "#2ECC71"
                                              : "#FF4F4F",
                                          fontSize: "110%",
                                        }}
                                      >
                                        {Statdata.diff_delivery_rate > 0
                                          ? "+"
                                          : ""}
                                        {Statdata.diff_delivery_rate.toFixed(
                                          2
                                        ) + "%"}
                                      </span>{" "}
                                      {Translate("dashboard", "fromlastperiod")}
                                    </span>
                                  ) : (
                                    ""
                                  )
                                }
                              />
                            </div>
                            <div className="col-half">
                              <StatsElement
                                title={Translate("dashboard", "orders41")}
                                value={
                                  this.showdata ? Statdata.delivery_count : "_"
                                }
                                subcontent={
                                  this.showdata ? (
                                    <span>
                                      <span
                                        style={{
                                          color:
                                            Math.floor(
                                              Statdata.diff_delivery_count
                                            ) > 0
                                              ? "#2ECC71"
                                              : "#FF4F4F",
                                          fontSize: "110%",
                                        }}
                                      >
                                        {Statdata.diff_delivery_count > 0
                                          ? "+"
                                          : ""}
                                        {Statdata.diff_delivery_count}
                                      </span>{" "}
                                      {Translate("dashboard", "fromlastperiod")}
                                    </span>
                                  ) : (
                                    ""
                                  )
                                }
                              />
                            </div>
                          </div>
                          <div className="FlWd InFlx responsFlx">
                            <div className="col-half">
                              <StatsElement
                                title={Translate("dashboard", "avgorderperday")}
                                value={
                                  this.showdata
                                    ? Statdata.avg_order_per_day.toFixed(2)
                                    : "_"
                                }
                                subcontent={
                                  this.showdata ? (
                                    // <span></span>
                                    <span>
                                      <span
                                        style={{
                                          color:
                                            Math.floor(
                                              Statdata.diff_avg_order_per_day
                                            ) > 0
                                              ? "#2ECC71"
                                              : "#FF4F4F",
                                          fontSize: "110%",
                                        }}
                                      >
                                        {Statdata.diff_avg_order_per_day > 0
                                          ? "+"
                                          : ""}
                                        {Statdata.diff_avg_order_per_day.toFixed(
                                          2
                                        )}
                                      </span>{" "}
                                      {Translate("dashboard", "fromlastperiod")}
                                    </span>
                                  ) : (
                                    ""
                                  )
                                }
                              />
                            </div>
                            <div className="col-half">
                              <StatsElement
                                title={Translate(
                                  "dashboard",
                                  "cancellationrate"
                                )}
                                value={
                                  this.showdata
                                    ? Math.floor(
                                        Statdata.cancelation_rate
                                      ).toFixed(2) + "%"
                                    : "_"
                                }
                                subcontent={
                                  this.showdata ? (
                                    <span>
                                      <span
                                        style={{
                                          color:
                                            Math.floor(
                                              Statdata.diff_cancelation_rate
                                            ) < 0
                                              ? "#2ECC71"
                                              : "#FF4F4F",
                                          fontSize: "110%",
                                        }}
                                      >
                                        {Statdata.diff_cancelation_rate > 0
                                          ? "+"
                                          : ""}
                                        {Statdata.diff_cancelation_rate.toFixed(
                                          2
                                        ) + "%"}
                                      </span>{" "}
                                      {Translate("dashboard", "fromlastperiod")}
                                    </span>
                                  ) : (
                                    ""
                                  )
                                }
                              />
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
              {dataProfile.view_payment && (
                <div className="col-one-three" style={{ padding: "0 2%" }}>
                  <div
                    className="FlHg FlWd StBrdTb StBgbrds ClSidTh SthgMaxC"
                    style={{ padding: "0% 3%", boxSizing: "border-box" }}
                  >
                    <div className="FlWd">
                      <div className="InFlx AlgnItm spctwB FlWd">
                        <h3 className="StdirLn">
                          {Translate("dashboard", "financials")}
                        </h3>
                        <div className="StDrc">
                          <GroupeBtn
                            data_btn={[
                              {
                                type: "BDgInpc",
                                action: () => {
                                  Event(
                                    "Dashboard",
                                    "CLICK_ON_VIEW_PAYEMENTS",
                                    "CLICK_EVENT"
                                  );
                                  this.props.history.push("/payments");
                                },
                                svgbtn: ViewSvg,
                              },
                            ]}
                          />
                        </div>
                      </div>
                      {ViewPay !== "1" ? (
                        <LoaderBln />
                      ) : (
                        <div>
                          {this.props?.dtStoreinfo?.country?.id === 1 ? (
                            <div className="TxtCn">
                              <StatsElement
                                title={Translate("payement", "payablebalance")}
                                value={FormatCurrency.format(
                                  ViewPaydata["solde a payer"]
                                )}
                              />
                              <StatsElement
                                title={Translate(
                                  "payement",
                                  "uncollectedbalance"
                                )}
                                value={FormatCurrency.format(
                                  ViewPaydata["solde en cours"]
                                )}
                              />
                            </div>
                          ) : (
                            <div className="TxtCn">
                              <StatsElement
                                title={Translate("payement", "payablebalance")}
                                value={
                                  ViewPaydata["solde a payer"] + "  " + "TND"
                                }
                              />
                              <StatsElement
                                title={Translate(
                                  "payement",
                                  "uncollectedbalance"
                                )}
                                value={
                                  ViewPaydata["solde en cours"] + "  " + "TND"
                                }
                              />
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
            {/* Top products table */}
            {dtStoreinfo.stock_managed && (
              <div className="FlWd flxDrc StMrtp2">
                <div className="FlWd StBgbrds ClSidTh StBrdTb">
                  <div className="RlPs" style={{ padding: "2%" }}>
                    <h3 className="StdirLn">
                      {Translate("dashboard", "topproducts")}
                    </h3>
                    <div style={{ marginBottom: "15px" }}>
                      {GetingTopProd === "0" ? (
                        <TableLoader />
                      ) : (
                        GetingTopProd === "1" && (
                          <Table
                            thead_elem={[
                              Translate("product", "productname").toUpperCase(),
                              Translate("product", "qt").toUpperCase(),
                              Translate("dashboard", "percent").toUpperCase(),
                            ]}
                            tbody_elem={bodytab}
                          />
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          {false && (
            <div
              className="col"
              style={{
                width: "25% !important",
                flexGrow: 0.25,
                padding: "0 2%",
              }}
            >
              <div
                className="FlHg FlWd StBrdTb StBgbrds ClSidTh SthgMaxC"
                style={{ padding: "2% 5%" }}
              >
                <div className="FlWd">
                  <div className="InFlx AlgnItm spctwB FlWd">
                    <h3 className="StdirLn">
                      {Translate("dashboard", "activity")}
                    </h3>
                    <div className="StDrc">
                      <GroupeBtn
                        data_btn={[
                          {
                            type: "BDgInpc",
                            action: () => this.Gotodetail("all"),
                            svgbtn: ViewSvg,
                          },
                        ]}
                      />
                    </div>
                  </div>
                </div>
                <div className="FlWd">
                  {OrderGeting === "2" &&
                    logs.length > 0 &&
                    logs.map((order, index) => {
                      let diff = date_difference(
                        new Date().now,
                        new Date(order.date)
                      );
                      return (
                        <div key={index}>
                          <p className="stfSize" style={{ marginBottom: 0 }}>
                            {" "}
                            Order #{order.order_id}{" "}
                            {this.getStatusAction(order.status)}
                          </p>
                          <p
                            className="StSizLn StOpcVal"
                            style={{ fontSize: 12, marginTop: "2px" }}
                          >
                            {Object.keys(diff).map(
                              (key) => `${diff[key]} ${key}`
                            )}{" "}
                            ago{" "}
                          </p>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          )}
        </div>
      </ContainerPage>
    );
  }
}
function mapState(state) {
  const { gtStoreInf, dtStoreinfo, dataProfile } = state.user;
  const { OrderGeting, OrdersList } = state.orders;
  const { GetinunfOrd, dataInfOrd, GetinunfOrdCount, dataInfOrdCount } =
    state.unfOrders;
  const { StockLoadded, DataStock } = state.Stock;
  const { data } = state.product;
  const {
    GetingStat,
    Statdata,
    GetingGlobalStat,
    GlobalStats,
    GettingTodayStats,
    TodayStats,
    GetingTopProd,
    TopProdData,
  } = state.dashboard;

  const { ViewPay, ViewPaydata } = state.Viewpayment;
  return {
    gtStoreInf,
    dtStoreinfo,
    dataProfile,
    OrderGeting,
    OrdersList,
    GetinunfOrd,
    dataInfOrd,
    StockLoadded,
    DataStock,
    data,
    GetingStat,
    Statdata,
    GetingGlobalStat,
    GlobalStats,
    GettingTodayStats,
    TodayStats,
    GetingTopProd,
    TopProdData,
    ViewPay,
    ViewPaydata,
    GetinunfOrdCount,
    dataInfOrdCount,
  };
}

const actionCreators = {
  GetStatus: dashboardActions.GetStatus,
  GetGlobalStats: dashboardActions.GetGlobalStats,
  GetTodayStats: dashboardActions.GetTodayStats,
  GetOrders: ordersActions.GetOrders,
  GetUnfOrders: ordersActions.GetUnfOrders,
  ViewStockProduct: productActions.ViewStockProduct,
  GetTopProduct: dashboardActions.GetTopProduct,
  GetAllProduct: productActions.GetAllProduct,
  ViewPayment: paymentActions.ViewPayment,
  GetUnfOrdersCount: ordersActions.GetUnfOrdersCount,
  GetCurrentorders: ordersActions.GetCurrentorders,
};

const connectedDashboardPage = connect(mapState, actionCreators)(DashboardPage);
const QueryProps = addUrlProps({ urlPropsQueryConfig })(connectedDashboardPage);
export { QueryProps as DashboardPage };
